import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { userLogin } from "../actions/index";
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import LeftSitebar from '../layout/LeftSitebar';

import ImgmA1 from '../assets/img/a1.png';
import ImgmStar from '../assets/img/star.png';
import ImgmCross from '../assets/img/cross.png';
import noFav from '../assets/img/no-favorites.png';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";

import toast, { Toaster } from 'react-hot-toast';
import 'react-loading-skeleton/dist/skeleton.css'
import { Link, Navigate } from 'react-router-dom';
import Currency from './Component/Currency';

import LocalizedStrings from "react-localization";
import localization from "../localization";

import Modal from 'react-bootstrap/Modal';
import Imgcross from '../assets/img/cross.png';
import { Button, Form } from 'react-bootstrap';
import { SelectGroup, ValidationForm } from "react-bootstrap4-form-validation";

let strings = new LocalizedStrings(localization);

const mapStateToProps = state => {
   return { user: state.user };
};

const mapDispatchToProps = dispatch => {
   return {
      userLogin: user => dispatch(userLogin(user))
   };
}



const Wishlist = (Props) => {
   const formRef = React.createRef();
   const [user, setUser] = useState(Object.keys(Props.user).length > 0 ? Props.user : null)
   const [comman, setComman] = useState({
      loginModel: false,
      qty: 1,
      product_id: 0,
      attributes: [],
      attribute_value_id: null,
      addCart: false
   });
   const [wishList, setWishList] = useState([]);

   const [currentLang, setCurrentLang] = useState(
      localStorage.getItem("current_lang")
         ? localStorage.getItem("current_lang")
         : "en"
   );
   strings.setLanguage(currentLang);


   const showSignBox = () => {
      setComman({ ...comman, loginModel: true });
   }
   const getList = async () => {
      let path = apiPath.getWishList;
      var data = new FormData();
      let options = {
         method: 'get',
         data: data
      }

      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {

            setWishList({ data: result.data.data });
            // toast.success(result.data.message);
         }
         else {
            setWishList([]);
            toast.error(result.data.message);
         }
      }
   }
   const deleteItem = async (id, type) => {

      let path = apiPath.AddRemoveWishlist;
      var data = new FormData()
      data.append('product_id', id);
      data.append('type', type);
      let options = {
         method: 'POST',
         data: data
      }

      let result = await Http(path, options);
      if (result.status === 200) {
         if (result.data.status) {
            toast.success(result.data.message);
            getList()
         } else {
            toast.error(result.data.message);
         }
      } else {
         if (result.statusText != null) {
            toast.error(result.statusText);
         }
      }


   }

   const [modalIsOpen, setModalIsOpen] = useState(false);

   const setModalIsOpenToTrue = (id, attributes) => {
      setComman({ ...comman, product_id: id, attributes: attributes });
      // alert(id)
      setModalIsOpen(true)
   }

   const setModalIsOpenToFalse = () => {
      setModalIsOpen(false)
   }

   const showAddtoCart = async (e, id, attributes) => {
      e.preventDefault();
      if (attributes.length > 0) {
         setModalIsOpenToTrue(id, attributes);
      }
      else {
         setComman({ ...comman, product_id: id, attribute_value_id: null });
         addtoCart(id, null);
      }
   };

   const addtoCart = async (id, type) => {
      let path = apiPath.AddtoCart;
      var data = new FormData();
      data.append('product_id', id);
      data.append('qty', 1);
      data.append('is_from_wishlist', 1);
      if (type == null && comman.attribute_value_id != null) {
         data.append('product_attribute_values_id', comman.attribute_value_id);
      }
      else if (type == 'form') {
         const attributeValueId = document.querySelector('input[name="attribute_value_id"]:checked');
         if (attributeValueId != null && attributeValueId != "") {
               data.append('product_attribute_values_id', attributeValueId.value);
         }
      }

      let options = {
         method: 'POST',
         data: data
      }

      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            toast.success(result.data.message);
            setComman({ ...comman, addCart: true });
            getList();
         }
         else {
            toast.error(result.data.message);
         }
         setModalIsOpenToFalse();
      }
   }

   useEffect(() => {
      getList();
   }, []);


   return (
      <>

         {/* {user ? (
            user.details.temp_user == 1 ? (
               <Navigate to="/" replace={true} />
            ) : (
               ""
            )
         ) : (
            <Navigate to="/" replace={true} />
         )} */}


         <Header wishList={wishList} />
         <main>
            <section className="space-cls my-account-page">
               <div className="container">
                  <div className="my-account-inner">

                     <LeftSitebar />
                     <div className="myaccount_r">
                        <div className="wishlist_item">
                           <h2 className="heading-type2">{strings.myWishlist}</h2>
                           <div className="row">
                              {wishList.data ? wishList.data.length > 0 ?
                                 wishList.data.map((item, index) =>
                                    <div className="col_2 col-4 col-sm-4 col-md-6 col-lg-4 col-xl-3 col_5">
                                       <div className="arrival-prd">
                                          <div className="prd_remove">
                                             <Link to="#" onClick={() => deleteItem(item.product.id, "remove")}>
                                                <img src={ImgmCross} />
                                             </Link>
                                          </div>
                                          <div className="arrival-prd-img">
                                             {/* <a href="product-detail.html"> */}
                                             <Link to={'/product-detail/' + item.product.slug} target="_blank">
                                                <img src={item.product ? item.product.image : ImgmA1} alt="" />
                                             </Link>
                                             {/* </a> */}
                                          </div>
                                          <div className="arrival-cont">
                                             <div className="sold_rate">
                                                {item.product.rating > 0 ? <>
                                                   <div className="ratting-wrap">
                                                      <span className="rate-dtl">{item.product.rating}&nbsp;</span>
                                                      <span className="ratting-icon">
                                                         <img src={ImgmStar} alt="Star" />
                                                      </span>
                                                      <span style={{ color: "#ccc" }} className='slash'>&nbsp;|&nbsp;</span>
                                                      <span className="rate-dtl">{item.product.total_rating_count}</span>
                                                   </div> </> : null}
                                                <div className="sold_dtl">
                                                   <p>{item.product.sold > 0 ? item.product.sold + " " + strings.sold + " & " + item.product.left + " " + strings.left : item.product.left + " " + strings.left}</p>
                                                </div>
                                             </div>
                                             <div className="arrival-title">
                                                <h3>{item.product ? item.product.brands ? item.product.brands.name : '' : ''} </h3>
                                                <p>{item.product ? item.product.name : ''}</p>
                                             </div>
                                             <div className="arrival-price">
                                                <p><Currency data={item.product.discount_price != 0 ? item.product.discount_price : item.product.original_price} /></p>
                                                {parseInt(item.product.discount_price) == 0 ? '' : <p className="discount-price">
                                                   <Currency data={item.product.original_price} /> ({(((item.product.original_price - item.product.discount_price) * 100) / item.product.original_price).toFixed(2)}% off)</p>}
                                                {/* <p className="discount-price">KWD {item.product? item.product.discount_prices :0} ({(((item.product.original_price-item.product.discount_price)*100)/item.product.original_price).toFixed(2)}% off)</p> */}
                                             </div>
                                          </div>
                                          <div className="prd-add-option">

                                             <div className="cart_wrap">
                                                {/* <Link onClick={() => addtoCart('addCart', item.product.id)} className="btn_primary">Add to cart</Link> */}
                                                <Link onClick={(e) => (item.product.out_of_stock == 0) || (item.product.left > 0) ? showAddtoCart(e, item.product.id, item.product.attributes) : window.location.href = "/product-detail/" + item.product.slug} className={(item.product.out_of_stock == 1) || (item.product.left <= 0) ? "btn_primary  notify_me_new" : "btn_primary"} target={"_blank"}>{(item.product.out_of_stock == 1) || (item.product.left <= 0) ? strings.notifyMe : strings.moveToCart}</Link>
                                             </div>
                                          </div>
                                       </div>
                                    </div>) : <section className="space-cls no_image">
                                    <div className="container">
                                       <div className="cart_row">
                                          <div className='col-md-12'>
                                             <h3>
                                                <center>
                                                   <img src={noFav} alt="Cart Product" />
                                                </center>
                                             </h3>
                                          </div>
                                       </div>
                                    </div>
                                 </section> : <section className="space-cls no_image">
                                 <div className="container">
                                    <div className="cart_row">
                                       <div className='col-md-12'>
                                          <h3>
                                             <center>
                                                <img src={noFav} alt="Cart Product" />
                                             </center>
                                          </h3>
                                       </div>
                                    </div>
                                 </div>
                              </section>}

                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </section>
         </main>
         <Footer />
         {modalIsOpen ? <>
            <Modal show={true} animation={true} size="lg" backdrop="static" keyboard={false} centered >
               <Modal.Header>
                  <h5 className="modal-title" id="exampleModalLabe111l">{strings.attributes}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                     <img src={Imgcross} onClick={setModalIsOpenToFalse} />
                  </button>
               </Modal.Header>
               <Modal.Body className='modal-body'>
                  <div className="continue-btn review_modal">
                     <ValidationForm ref={formRef} >
                        <div className="popup-cont">
                           <Form.Group className="form-group" controlId="form-group">
                              <div className="prd-size">
                                 <div className="prd_size_label">
                                    {comman.attributes[0].attribute_values && comman.attributes[0].attribute_values.map((option, i) => (
                                          <label className="custom_radio_b" key={option.id}>
                                                <input
                                                      type="radio"
                                                      name="attribute_value_id"
                                                      value={option.id}
                                                      defaultChecked={i === 0}
                                                />
                                                <span className="checkmark">{option.value}</span>
                                          </label>
                                    ))}
                                 </div>
                              </div>
                           </Form.Group>
                           <div className="login-btn">
                              <Button onClick={(e) => addtoCart(comman.product_id, 'form')} type="button" className="btn btn_primary">{strings.add_to_bag}</Button>
                           </div>
                        </div>
                     </ValidationForm>
                  </div>
               </Modal.Body>
            </Modal></> : ""}
      </>
   )
}
export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
// export default Wishlist;