import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import LocalizedStrings from 'react-localization';
import localization from '../../localization';
import GoogleLogin from './GoogleLogin';

let strings = new LocalizedStrings(localization);

const ShowSignBox = ({ onLoginClicked }) => {
    return (
        <Modal show={true} onHide={onLoginClicked} animation={true} centered>
            <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">{strings.sign_in}</h5>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <div className="popup-cont">
                    <p className="signin-cont">{strings.special_offer}</p>
                    <div className="continue-btn">
                        <Link to="#" className="btn btn_primary mb-3" onClick={onLoginClicked}>{strings.continue_with_phone}</Link>
                        <div className="App">
                            <GoogleLogin />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ShowSignBox;
