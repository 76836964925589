import axios from "axios";
export const internalIpV6 = () => { };
export const internalIpV4 = () => {

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://api.ipify.org/?format=json',
        headers: {}
    };

    return axios.request(config)
        .then((response) => {
            // return JSON.stringify(response.data);
            // console.log();
            return response.data.ip
            // setIP((response.data.ip));
        })
        .catch((error) => {
            console.log(error);
        });
    // return '100.100.100.100'
}