import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Imgcross from '../../assets/img/cross.png';

import toast, { Toaster } from 'react-hot-toast';

import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";

import { ValidationForm, TextInput, TextInputGroup, FileInput, SelectGroup, Checkbox } from "react-bootstrap4-form-validation";
import validator from 'validator'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import Login from './Login';
import OtpVerifyForgetPassword from './OtpVerifyForgetPassword';

import { userLogin } from "../../actions/index";
import { connect } from "react-redux";

import LocalizedStrings from 'react-localization';
import localization from '../../localization';
let strings = new LocalizedStrings(localization);

const mapStateToProps = state => {
    return { user: state.user };

};

const mapDispatchToProps = dispatch => {
    return {
        userLogin: user => dispatch(userLogin(user))
    };
};

const ForgotPassword = (Props) => {
    const [showRegister, setShowRegister] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [getRegister, setRegister] = useState({
        mobile: '',
        country_code: '',
        name: '',
    });


    const [currentLang, setCurrentLang] = useState(
        localStorage.getItem("current_lang")
            ? localStorage.getItem("current_lang")
            : "en"
    );
    strings.setLanguage(currentLang);

    const formRef = React.createRef();

    const registerClose = () => setShowRegister(false);

    const openLogin = () => {
        setShowRegister(false);
        setShowLogin(true);
    }

    const registerSubmit = async (e, formData, inputs) => {
        e.preventDefault();
        if (validate()) {
            let path = apiPath.forgotPassword;
            var data = new FormData();

            console.log(formData)
            data.append('email', formData.email);
            let options = {
                method: 'POST',
                data: data
            }
            let result = await Http(path, options);
            if (result.status === 200) {
                if (result.data.status) {

                    setShowRegister(false);
                    setShowOtp(true);

                } else {
                    toast.error(result.data.message);
                }
            } else {
                if(result.statusText != null) {
                    toast.error(result.statusText);
                }
            }
        }
    }
    const validate = () => {
        let isValid = true;
        // if (getRegister.mobile.length === 0) {
        //     toast.error('Mobile is required');
        //     isValid = false;
        // }
        // if (getRegister.mobile.length > 0) {
        //     if (getRegister.mobile.match(/^[0-9]{0,7}$/)) {
        //         toast.error('Required 8 To 13 Digit');
        //         isValid = false;
        //     }
        // }
        return isValid;
    }
    const registerErrorSubmit = (e, formData, inputs) => {
        e.preventDefault();
    }
    const handleChange = (e) => {
        console.log(e.target.value)
        // var country_code = data.dialCode;
        //  setRegister({ country_code: country_code, mobile: value.slice(data.dialCode.length) });
        setRegister({ email: e.target.value });
    }
    useEffect(() => {
        setShowRegister(Props.data);
    }, [Props]);
    return (
        <>
            {showOtp ? <OtpVerifyForgetPassword data={showOtp} userData={getRegister} /> : ''}

            <Modal show={showRegister} onHide={registerClose} animation={true} size="lg" backdrop="static" keyboard={false} centered >
                <Modal.Header >
                    <h5 className="modal-title" id="exampleModalLabel">{strings.forget_password}</h5>
                    <button type="button" className="btn-close" onClick={registerClose}>
                        <img src={Imgcross} onClick={registerClose} />
                    </button>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    <ValidationForm onSubmit={registerSubmit} onErrorSubmit={registerErrorSubmit} ref={formRef} >

                        <div className="popup-cont">
                            <Form.Group className="form-group" controlId="form-group">
                                <Form.Label>{strings.enter_email}</Form.Label>
                                <TextInputGroup name="email" id="email" type="email"
                                    placeholder={strings.plz_enter_email}
                                    validator={validator.isEmail}
                                    errorMessage={{ validator: strings.plz_enter_valid_email }}
                                    // value={user.email}
                                    onChange={handleChange}
                                    required
                                />

                                {/* <Form.Control type="email" placeholder={strings.enter_email} name="email" onChange={handleChange} required /> */}
                                {/* <PhoneInput inputProps={{
                                    name: "mobile",
                                    required: true,
                                    autoFocus: false,
                                }}
                                    isValid={(value, country) => {
                                        if (value.match(/1234/)) {
                                            return 'Invalid value: ' + value + ', ' + country.name;
                                        } else if (value.match(/1234/)) {
                                            return 'Invalid value: ' + value + ', ' + country.name;
                                        } else {
                                            return true;
                                        }
                                    }}
                                    country={"kw"} value={getRegister.phone} onChange={handleChange} /> */}
                            </Form.Group>
                            <div className="login-btn">
                                <Button type="submit" className="btn btn_primary">{strings.forget_password}</Button>
                            </div>
                            {/* <div className="already_cls">
                                <p>{strings.already_account} <Link to="#" onClick={openLogin}>{strings.sign_in}</Link></p>
                            </div> */}
                        </div>
                    </ValidationForm>
                </Modal.Body>
            </Modal>
        </>

    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)