import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import Imgcross from '../../assets/img/cross.png';
import Register from './Register';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import toast, { Toaster } from 'react-hot-toast';
import { internalIpV6, internalIpV4 } from '../../services/getIp';
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import { ValidationForm, TextInput, TextInputGroup, FileInput, SelectGroup, Checkbox } from "react-bootstrap4-form-validation";
import validator from 'validator'
import LocalizedStrings from 'react-localization';
import localization from '../../localization';
import { userLogin } from "../../actions/index";
import { connect } from "react-redux";
import ForgotPassword from './ForgotPassword';
import axios from 'axios'
import OtpVerify from '../Modal/OtpVerify';
import eyeSvgrepoCom from "../../assets/img/eye-svgrepo-com.svg";
import eyeOffSvgrepoCom from "../../assets/img/eye-off-svgrepo-com.svg";
let strings = new LocalizedStrings(localization);
const mapStateToProps = state => {
    return { user: state.user };

};

const mapDispatchToProps = dispatch => {
    return {
        userLogin: user => dispatch(userLogin(user))
    };
};
const Login = (Props) => {


    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [gtIp, setIP] = useState()

    const [getLogin, setLogin] = useState({
        phone: "",
    });

    const [currentLang, setCurrentLang] = useState(
        localStorage.getItem("current_lang")
            ? localStorage.getItem("current_lang")
            : "en"
    );
    strings.setLanguage(currentLang);
    const formRef = React.createRef();

    const loginClose = () => {
        setShowLogin(false);
        if (Props.onClose) {
            Props.onClose();
        }
    };

    const [getRegister, setRegister] = useState({
        email: '',
    });
    const openRegister = () => {
        setShowLogin(false);
        setShowRegister(true);
    }

    const openForgetPassword = () => {
        // alert("hello");
        setShowLogin(false);
        setShowPassword(true);
        // setShowRegister(true);
    }
    const getIP = () => {
        // const axios = require('axios');

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://api.ipify.org/?format=json',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                // return JSON.stringify(response.data);
                // console.log();
                setIP((response.data.ip));
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const loginSubmit = async (e, formData, inputs) => {
        // alert("hello")
        // console.log(formData)
        e.preventDefault();
        if (validate()) {

            let path = apiPath.login;
            var data = new FormData();
            data.append('device_type', 'Website');
            data.append('device_token', await internalIpV4());
            data.append('email', formData.email);
            data.append('password', formData.password);
            let options = {
                method: 'POST',
                data: data
            }
            let result = await Http(path, options);
            if (result.status === 200) {
                // console.log(result.data)
                if (result.data.status == true) {
                    setShowLogin(false);
                    Props.userLogin(result.data.data);
                    toast.success(result.data.message);
                    window.location.reload();
                    // setShowOtp(true);
                }
                // if (result.data.status === 404) {
                //     setRegister({ email: formData.email })
                //     setShowOtp(true)
                //     setShowLogin(false);
                //     toast.error(result.data.message);
                // }
                else {
                    // setRegister({ email: formData.email })
                    // setShowOtp(true)
                    // setShowLogin(false);
                    toast.error(result.data.message);
                }
            } else {
                if(result.statusText != null) {
                    toast.error(result.statusText);
                }
            }
        }
    }
    const validate = () => {
        let isValid = true;
        return isValid;
    }
    const loginErrorSubmit = (e, formData, inputs) => {
        e.preventDefault();
    }

    useEffect(() => {
        setShowLogin(Props.data);
        getIP();
    }, [Props]);
    return (
        <>
            {showPassword ? <ForgotPassword data={showPassword} /> : ''}
            {showRegister ? <Register data={showRegister} /> : ''}
            {showOtp ? <OtpVerify data={showOtp} userData={getRegister} /> : ''}
            <Modal show={showLogin} onHide={loginClose} animation={true} size="lg" backdrop="static" keyboard={false} centered>
                <Modal.Header >
                    <h5 className="modal-title" id="exampleModalLabel">{strings.sign_in}</h5>
                    <button type="button" className="btn-close" onClick={loginClose}>
                        <img src={Imgcross} />
                    </button>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    <ValidationForm onSubmit={loginSubmit} onErrorSubmit={loginErrorSubmit} ref={formRef} >
                        <div className="popup-cont">
                            <Form.Group className="form-group" controlId="form-group">
                                <Form.Label>{strings.enter_email}</Form.Label>
                                <TextInputGroup type="email" placeholder={strings.enter_email} name="email" required />
                                {/* <PhoneInput country={'kw'} value={getLogin.phone} /> */}
                            </Form.Group>
                            <Form.Group className="form-group position-relative">
                                <Form.Label>Enter Password</Form.Label>
                                <TextInputGroup type={passwordType} name="password" onChange={handlePasswordChange} value={passwordInput} placeholder="Password" required />
                                <div className="input-group-btn passShowHideBtn position-absolute">
                                    {/* <button className="btn btn-outline-primary"> */}
                                    {passwordType === "password" ? <img className='w-100' src={eyeSvgrepoCom} onClick={togglePassword} /> : <img className='w-100' src={eyeOffSvgrepoCom} onClick={togglePassword} />}
                                    {/* </button> */}
                                </div>
                            </Form.Group>

                            <div className="login-btn">
                                <Button type="submit" className="btn btn_primary">
                                    {strings.sign_in}
                                </Button>
                            </div>
                            <div className="already_cls">
                                <p>{strings.new_account} <Link to="#" onClick={openRegister} >{strings.sign_up}</Link></p>
                                <p><Link to="#" onClick={openForgetPassword} >{strings.forget_password}</Link></p>
                            </div>
                        </div>

                    </ValidationForm>
                </Modal.Body>
            </Modal>



        </>

    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Login); 