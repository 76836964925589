/*global google*/
import React from 'react';
import { compose, withProps,lifecycle } from "recompose";
import { withScriptjs,withGoogleMap,GoogleMap,Marker} from "react-google-maps";
import Geocode from "react-geocode";
const _ = require("lodash");
Geocode.setApiKey('AIzaSyAYUNU2J6j821wNjA48vrDQxXHXV5c0Kmc')
Geocode.setRegion("es");
Geocode.setLanguage("en");

const MapWithASearchBox = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAYUNU2J6j821wNjA48vrDQxXHXV5c0Kmc&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentDidMount() {
      const refs = {}
      this.setState({
        bounds: null,
        /*center: {
          lat: 20.5937, lng: 78.9629
        },
        markers: [],*/
        center: {
          lat: parseFloat(this.props.lat ?? 25.3366), lng:  parseFloat(this.props.lng ??51.4809)
        },
        markers: [{position:{lat: parseFloat(this.props.lat ?? 25.3366), lng:  parseFloat(this.props.lng ??51.4809)}}],
        onMapMounted: ref => {
          refs.map = ref;
        },

        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          })
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {       
          const places = refs.searchBox.getPlaces();
          const bounds = new google.maps.LatLngBounds();
    
          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport)
            } else {
              bounds.extend(place.geometry.location)
            }
          });
          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
          }));
          places.map(
            ({ place_id, formatted_address,address_components, geometry: { location } }) => (
      
              this.setState({address : formatted_address,
                address_components:address_components,
                lat : location.lat(),
                lng : location.lng()
              })
            )
          )
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);
          this.setState({
            center: nextCenter,
            markers: nextMarkers,
            places : places
          });
          // refs.map.fitBounds(bounds);
        },
        onMarkerMounted: ref => {
            refs.marker = ref;
        },
        onPositionChanged: () => {
          const position = refs.marker.getPosition();
          var formatted_address = '';
          var address_components = '';
          var lat = position.lat()
          var lng = position.lng()
          Geocode.fromLatLng(lat,lng).then(
            response => {
              formatted_address = response.results[0].formatted_address;
              address_components = response.results[0].address_components;
              this.setState({address : formatted_address,address_components:address_components,lat:lat,lng:lng})
            },
            error => {
              console.error(error);
            }
          );
          var addressField = document.getElementsByClassName('pac-target-input')
          addressField[0].value = this.state.address;
        },
      })
    },
  }),
  withScriptjs,
  withGoogleMap
)(props =>{
  return <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={15}
    center={props.center}
    onBoundsChanged={props.onBoundsChanged}
  >
    {
      props.markers.map((marker, index) =>
      <Marker key={index} position={marker.position} draggable={true}  ref={props.onMarkerMounted} onPositionChanged={props.onPositionChanged} />
    )}
  </GoogleMap>
}
);
export default MapWithASearchBox;