import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from "react-router-dom";
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import LeftSitebar from '../layout/LeftSitebar';
import ImgDots from '../assets/img/dots.png';
import ImgDelete from '../assets/img/delete.png';

import apiPath from "../constants/apiPath";
import Http from "../services/Http";

import toast, { Toaster } from 'react-hot-toast';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import noNotification from '../assets/img/notification-not-found.png';
import { userLogin } from "../actions/index";
import { connect } from "react-redux";
import DayPipe from '../constants/DayPipe';

import Dropdown from 'react-bootstrap/Dropdown';

import LocalizedStrings from 'react-localization';
import localizations from "../localization";
let strings = new LocalizedStrings(localizations);


const mapStateToProps = state => {
  return { user: state.user };
};

const mapDispatchToProps = dispatch => {
  return {
    userLogin: user => dispatch(userLogin(user))
  };
}



const Notification = (Props) => {

  const [user, setUser] = useState(Object.keys(Props.user).length > 0 ? Props.user : null);
  const [notification, setNotification] = useState([]);


  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
      ? localStorage.getItem("current_lang")
      : "en"
  );
  strings.setLanguage(currentLang);




  const getNotification = async () => {

    let path = apiPath.notificationList;
    var data = new FormData();
    let options = {
      method: 'GET',
      data: data
    }

    let result = await Http(path, options);
    if (result.status == 200) {
      if (result.data.status) {
        setNotification({ data: result.data.data })
        toast.success(result.data.message);
      }
      else {
        toast.error(result.data.message);
      }
    }

  }

  const deleteNotification = async (id) => {
    if (window.confirm(strings.are_you_sure_clear_notification) == true) {
      let path = apiPath.removeNotification;
      var data = new FormData();
      data.append('notification_id', id);
      let options = {
        method: 'POST',
        data: data
      }
      let result = await Http(path, options);
      if (result.status == 200) {
        if (result.data.status) {
          getNotification();
          toast.success(result.data.message);
        }
        else {
          toast.error(result.data.message);
        }
      }
    }
  }
  const deleteAll = async () => {
    if (window.confirm(strings.are_you_sure_clear_all_notification) == true) {
      let path = apiPath.removeAllNotification;
      var data = new FormData();
      let options = {
        method: 'GET',
        data: data
      }
      let result = await Http(path, options);
      if (result.status == 200) {
        if (result.data.status) {
          getNotification();
          toast.success(result.data.message);
        }
        else {
          toast.error(result.data.message);
        }
      }
    }

  }
  const readAll = async () => {
    let path = apiPath.readAllNotification;
    var data = new FormData();
    let options = {
      method: 'GET',
      data: data
    }
    let result = await Http(path, options);
    if (result.status == 200) {
      if (result.data.status) {
        getNotification();
        toast.success(result.data.message);
      }
      else {
        toast.error(result.data.message);
      }
    }
  }
  const readNotification = async (e, id) => {
    let path = apiPath.readNotification;
    var data = new FormData();
    data.append('notification_id', id);
    let options = {
      method: 'POST',
      data: data
    }
    let result = await Http(path, options);
    if (result.status == 200) {
      if (result.data.status) {
        getNotification();
        toast.success(result.data.message);
      }
      else {
        toast.error(result.data.message);
      }
    }
    // alert(id)
  }
  useEffect(() => {
    getNotification();
  }, [Props]);



  return (
    <>

      {user ? user.details.temp_user == 1 ? <Navigate to="/" replace={true} /> : '' : <Navigate to="/" replace={true} />}


      <Header />
      <main>

        <section className="space-cls my-account-page">
          <div className="container">
            <div className="my-account-inner">
              <LeftSitebar />
              <div className="myaccount_r">
                <div className="notification_page">
                  <div className="notification_title">
                    <h2 className="heading-type2">{strings.notifications}</h2>
                    <div className="ms-auto notification-drop dropdown">
                      <Dropdown>
                        <Dropdown.Toggle className="dropdown-toggle" id="dropdown-basic">
                          <img src={ImgDots} alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={deleteAll}>{strings.clear_all}</Dropdown.Item>
                          <Dropdown.Item onClick={readAll} >{strings.mark_all_as_read}</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="notification_wrap">
                    {notification.data ? notification.data.length > 0 ? notification.data.map((item, index) =>
                      <>

                        <div className={item.is_read == 0 ? "unread_notification noti_space" : 'read_notification noti_space'} >
                          {item.order_id ? <>
                            <Link to={'/order-detail/' + item.order_id} onClick={((e) => readNotification(e, item.id))}>
                              <p><h4>{item.title}</h4></p>
                              <p>{item.message}</p>


                            </Link> </> : <>  <p><h4>{item.title}</h4></p>
                            <p>{item.message}</p> </>}
                          <div className="time_remove">

                            <div className="noti_time">
                              <span><DayPipe date={item.created_at} /></span>
                            </div>

                            <div className="noti_remove" onClick={() => deleteNotification(item.id)}>
                              <span><img src={ImgDelete} alt="" /></span>
                            </div>
                          </div>
                        </div>

                      </>
                    ) : <section className="space-cls no_image">
                      <div className="container">
                        <h3>
                          <center>
                            <img src={noNotification} alt="Cart Product" />
                          </center>
                        </h3>
                      </div>
                    </section> : <section className="space-cls no_image">
                      <div className="container">
                        <h3>
                          <center>
                            <img src={noNotification} alt="Cart Product" />
                          </center>
                        </h3>
                      </div>
                    </section>}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>
      <Footer />
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(Notification);