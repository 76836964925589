// src/js/store/index.js
import { createStore } from "redux";
import rootReducer from "../reducers/index";
const persistedState = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : { user: {}}

const store = createStore(rootReducer, persistedState);
store.subscribe(() => {
    localStorage.setItem('user', JSON.stringify(store.getState()))
})
export default store;
