import React, { useState, useEffect } from 'react';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { Helmet } from 'react-helmet';

import LocalizedStrings from 'react-localization';
import localizations from "../localization";
let strings = new LocalizedStrings(localizations);

const Faq = () => {
    const [currentLang, setCurrentLang] = useState(
        localStorage.getItem("current_lang")
          ? localStorage.getItem("current_lang")
          : "en"
    );
    strings.setLanguage(currentLang);

    const [page, setPage] = useState([]);
    const getPage = async () => {
        let path = apiPath.staticContent + '/faq';
        var data = new FormData();
        let options = {
            method: 'GET',
            data: data
        }
        let result = await Http(path, options);
        if (result.status === 200) {
            if (result.data.status) {
                setPage(result.data.data)
            } else {
                toast.error(result.data.message);
            }
        } else {
            if (result.statusText != null) {
                toast.error(result.statusText);
            }
        }
    }

    useEffect(() => {
        getPage();
    }, []);

    const metaLocal = {
        en: {
            title: "Frequently Asked Questions - Perfume Deals in Kuwait",
            description: "Find answers to your perfume-related questions in Kuwait. Learn about our best fragrance offers, shipping policies, and more. Get your favorite scent today!",
            keyword: "perfume-related questions in Kuwait"
        },
        ar: {
            title: "الأسئلة المتداولة - عروض العطور في الكويت",
            description: "احصل على إجابات لأسئلتك المتعلقة بالعطور في الكويت. تعرف على أفضل عروض العطور وسياسات الشحن والمزيد. احصل على رائحتك المفضلة اليوم!",
            keyword: "أسئلة متعلقة بالعطور في الكويت"
        }
      };

    return (
        <>
            <Helmet>
                <title>{metaLocal[currentLang].title}</title>
                <meta name="description" content={metaLocal[currentLang].description} />
                <meta name="keywords" content={metaLocal[currentLang].keyword} />
            </Helmet>
            <Header />
            <main>
                <section className="space-cls cart-page">
                    <div className="container">
                        <div className="inner-tit">

                            <div>
                                <h2>{page ? page.name : ''}</h2>
                                <br />
                                <p>
                                    <p>
                                        <div dangerouslySetInnerHTML={{ __html: page ? page.description : '' }}></div>
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>


            <Footer />
        </>

    )
}
export default Faq;