import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { Link } from "react-router-dom";
import LocalizedStrings from 'react-localization';
import localizations from "../localization";
let strings = new LocalizedStrings(localizations);
const NotFound = () =>{


  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
        ? localStorage.getItem("current_lang")
        : "en"
);
strings.setLanguage(currentLang);


    return (
        <>
        <Header/>
        <main>
            <section className="space-cls cart-page not_found_page">
                <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="notfound-inner">
                            <h1>404</h1>
                            <h3>{strings.page_not_found}</h3>
                            <p>{strings.we_could_not_find}</p>
                            <Link to="/" className="btn btn_primary">{strings.back_to_home_page}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <Footer/>
        </>
    )
}
export default NotFound;
