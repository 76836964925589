
import React, { Component } from 'react';
import Moment from 'react-moment';
const moment = require('moment');
class UtCToLocal extends Component {
	render() {
		if (this.props.date != '') {
			// alert(this.props.date, "")
			  return  new Date(Date.parse(this.props.date+' UTC')).toLocaleString();
			 // return moment.utc(dates).format('YYYY-MM-DD');

		} else {
			return ''
		}
	}
}

export default UtCToLocal;