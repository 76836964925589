import React, { useRef, useState, useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import LeftSitebar from '../layout/LeftSitebar';

import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import { SelectGroup, TextInput, TextInputGroup, ValidationForm } from 'react-bootstrap4-form-validation';
import { Modal, Form, Button, Col, Row } from 'react-bootstrap';
import { Navigate } from 'react-router';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import LocalizedStrings from 'react-localization';
import localizations from "../localization";
let strings = new LocalizedStrings(localizations);


const options = {
	margin: 0,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 1,
			margin: 7.5
		},
		420: {
			items: 1
		},
		577: {
			items: 1
		},
		992: {
			items: 1
		}
	}
};

const newAriveOption = {
	margin: 15,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 1,
			margin: 7.5
		},
		420: {
			items: 2.5,
			margin: 7.5
		},
		577: {
			items: 3,
			margin: 7.5
		},
		767.98: {
			items: 4
		},
		992: {
			items: 5
		},
		1366: {
			items: 7
		}
	}
}


const Address = () => {
	const [user, setUser] = useState([]);
	const [common, setCommon] = useState({
		immediate: true,
		setFocusOnError: true,
		clearInputOnReset: false,
		image: '',
		is_redirect: false
	});
	const formRef = React.createRef();
	const [country, setCountry] = useState([]);
	const [state, setState] = useState([]);
	const [addressType, setAddressType] = useState();
	const [placeAddress, setPlaceAddress] = useState()
	const [getRegister, setRegister] = useState({
		mobile: '',
		country_code: '',
	});
	const autoCompleteRef = useRef();
	const inputRef = useRef();


	const [currentLang, setCurrentLang] = useState(
		localStorage.getItem("current_lang")
			? localStorage.getItem("current_lang")
			: "en"
	);
	strings.setLanguage(currentLang);

	// const { ref } = usePlacesWidget({
	// 	apiKey: "AIzaSyAYUNU2J6j821wNjA48vrDQxXHXV5c0Kmc",
	// 	onPlaceSelected: (place) => {
	// 	},
	// });
	const getCountry = async () => {
		let path = apiPath.getCountry;
		var data = new FormData();
		let options = {
			method: 'POST',
		}
		let result = await Http(path, options);
		if (result.status === 200) {
			if (result.data) {

				let resultJson = result.data.result;
				await setCountry({
					data: result.data.data,
				})



			} else {
				toast.error(result.data.message);
			}
		} else {
			if(result.statusText != null) {
				toast.error(result.statusText);
			}
		}
	}

	const onSubmit = async (e, formData, inputs) => {
		e.preventDefault();
		let path = apiPath.addEditAddress;
		formData.address_type = addressType ? addressType.address_type : "Home"
		formData.address = placeAddress ? placeAddress.data ? placeAddress.data.formatted_address : '' : ''
		formData.mobile_number = getRegister ? getRegister.mobile : ""
		formData.country_code = getRegister ? getRegister.country_code : ''
		formData.is_default = 1
		let options = {
			method: 'POST',
			data: formData
		}
		let result = await Http(path, options);
		if (result.status === 200) {
			if (result.data.status) {

				toast.success(result.data.message);
				window.location.href = "/profile"

			} else {
				toast.error(result.data.message);
			}
		} else {
			if(result.statusText != null) {
				toast.error(result.statusText);
			}
		}
		//   }
	}
	const handleChange = (value, data, event, formattedValue) => {
		var country_code = data.dialCode;
		setRegister({ country_code: country_code, mobile: value.slice(data.dialCode.length) });
	}
	const addressErrorSubmit = (e, formData, errorInputs) => {
	}
	const handleChange1 = (e) => {
		if (e.target.name == "country_id") {
			country.data && country.data.map((value) => {

				if (value.id == e.target.value) {
					setState({ data: value.state })
				}
			})
		}
		setUser({ ...user, [e.target.name]: e.target.value })
	}
	const onRadioChange = (e) => {
		setAddressType({ [e.target.name]: e.target.value })

	}
	useEffect(() => {

		// const address = async () => {
		// 	autoCompleteRef.current = new window.google.maps.places.Autocomplete(
		// 		inputRef.current,
		// 		options
		// 	);
		// 	autoCompleteRef.current.addListener("place_changed", async function () {
		// 		const place = await autoCompleteRef.current.getPlace();

		// 		setPlaceAddress({ data: place })


		// 	})

		// }
		getCountry();
		// address();



	}, []);
	return (
		<>
			{common.is_redirect ? <Navigate to="/profile" replace={true} /> : ''}
			<Header />
			<main>

				<section className="space-cls address-page">
					<div className="container">
						<div className="my-account-inner">
							<LeftSitebar />
							<div className="myaccount_r">

								<div className="added-address">

									<ValidationForm onSubmit={onSubmit}
										onErrorSubmit={addressErrorSubmit}
										ref={formRef}
										immediate={common.immediate}
										setFocusOnError={common.setFocusOnError}
										defaultErrorMessage={{ required: "Please enter something." }}
									>
										<h2 className="heading-type2">{strings.shipping_address}</h2>
										<div className="address_form">
											<Row>
												<Col md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>Country</Form.Label>

														<SelectGroup className="form-control" name="country_id" id="country_id"
															onChange={handleChange1}
															required errorMessage={strings.plz_select_country}
														>
															<option value="">{strings.plz_select_country}</option>
															{country.data && country.data.map((option, index) => {
																return <option key={option.id} value={option.id}>
																	{option.name}
																</option>
															})}
														</SelectGroup>
													</Form.Group>
												</Col>
												{state.data ? state.data.length > 0 ?
													<Col md="6">
														<Form.Group className="form-group" controlId="form-group">
															<Form.Label>{strings.state}</Form.Label>

															<SelectGroup className="form-control" name="state_id" id="state_id"
																onChange={handleChange1}
																errorMessage={strings.plz_select_state}
															>
																<option value="">{strings.plz_select_state}</option>
																{state.data && state.data.map((option, index) => {
																	return <option key={option.id} value={option.id} >
																		{option.name}
																	</option>
																})}
															</SelectGroup>
														</Form.Group>
													</Col>
													: <Col md="6">
														<Form.Group className="form-group" controlId="form-group">
															<Form.Label>{strings.state}</Form.Label>

															<TextInput name="state_id" id="state_id" required

																placeholder={strings.plz_enter_state}
																errorMessage={strings.plz_enter_state}
																onChange={handleChange1}
															/>


														</Form.Group>
													</Col> : <Col md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.state}</Form.Label>
														<TextInput name="state_id" id="state_id" required

															placeholder={strings.plz_enter_state}
															errorMessage={strings.plz_enter_state}
															onChange={handleChange1}
														/>

													</Form.Group>
												</Col>}
												<Col md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.city}</Form.Label>
														<TextInput name="city_id" id="city_id" required

															placeholder={strings.plz_enter_city}
															errorMessage={strings.plz_enter_city}
															onChange={handleChange1}
														/>
													</Form.Group>

												</Col>

												<Col md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label> {strings.Block}</Form.Label>
														<TextInput name="block" id="block" required

															placeholder={strings.plz_enter_block}
															errorMessage={strings.plz_enter_block}
															onChange={handleChange1}
														/>
													</Form.Group>

												</Col>

												<Col md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label> {strings.street}</Form.Label>
														<TextInput name="street" id="street" required

															placeholder={strings.plz_enter_street}
															errorMessage={strings.plz_enter_street}
															onChange={handleChange1}
														/>
													</Form.Group>

												</Col>

												<Col md="6">

													<Form.Group className="form-group" controlId="form-group">
														<Form.Label> {strings.landmark} </Form.Label>
														<TextInput name="landmark" id="area" required

															placeholder={strings.plz_enter_landmark}
															errorMessage={strings.plz_enter_landmark}
															onChange={handleChange1}
														/>
													</Form.Group>

												</Col>

												<Col md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.flat_number}</Form.Label>
														<TextInput name="flat_floor" id="flat_floor" required

															placeholder={strings.plz_enter_flat_number}
															errorMessage={strings.plz_enter_flat_number}
															onChange={handleChange1}
														/>
													</Form.Group>

												</Col>
												<Col md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>Mobile number</Form.Label>
														<PhoneInput inputProps={{
															name: "mobile",
															required: true,
															autoFocus: false,
														}}
															isValid={(value, country) => {
																if (value.match(/1234/)) {
																	return 'Invalid value: ' + value + ', ' + country.name;
																} else if (value.match(/1234/)) {
																	return 'Invalid value: ' + value + ', ' + country.name;
																} else {
																	return true;
																}
															}}
															country={"kw"} onChange={handleChange} />
													</Form.Group>
												</Col>

												{/* <Col md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.address}</Form.Label>
														<input className='form-control' ref={inputRef} name="address" id="address"

															placeholder={strings.plz_enter_address}
															errorMessage={strings.plz_enter_address}
															onChange={handleChange1} />

													</Form.Group>

												</Col> */}

												<Col md="12" lg="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.note}</Form.Label>
														<TextInput name="notes" id="notes"

															placeholder={strings.pls_enter_note}
															errorMessage={strings.pls_enter_note}
															onChange={handleChange1}
														/>
													</Form.Group>

												</Col>
												<Col md="12" lg="6">
													<Form.Group>
														<div className="form-group">
															<Form.Label>{strings.addressType}</Form.Label>
															<div className='d-flex custom_slt'>
																<label className="custom_radio_b">
																	<input type="radio" name="address_type" value="Home" onChange={onRadioChange} />
																	<span className="checkmark"></span>{strings.home}
																</label>

																<label className="custom_radio_b">
																	<input type="radio" name="address_type" value="Office" onChange={onRadioChange} />
																	<span className="checkmark"></span>{strings.office}
																</label>

																<label className="custom_radio_b">
																	<input type="radio" name="address_type" value="Other" onChange={onRadioChange} />
																	<span className="checkmark"></span>{strings.other}
																</label>
															</div>
														</div>
													</Form.Group>
												</Col>
												<Col md="6">
													<div className="form-group form-submit">
														<button type="submit" className="btn btn_primary">Submit</button>
													</div>
												</Col>
											</Row>
										</div>
									</ValidationForm>
								</div>
							</div>
						</div>
					</div>
				</section>


			</main>
			<Footer />
		</>
	)
}
export default Address;