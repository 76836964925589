import { Outlet, Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { Helmet } from 'react-helmet';

const Layout = () => {
  return (
    <>
      {/* <ScrollToTop /> */}
      <Helmet>
        <title>Best Perfume in Kuwait | Men, Women, Unisex & Bakhoor | TPS</title>
        <meta name="description" content="Best perfume for men, women, and unisex, including bakhoor and beauty products. Available for delivery in Kuwait from The Perfume Smell" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content="Perfume Smell" />
        <meta property="og:description" content="Discover the best perfumes and scents!" />
        <meta property="og:image" content="https://theperfumesmell.com/admin/uploads/settings/APR2024/1714469356-settings.png" />
        <meta property="og:url" content={window.location.href} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "The Perfume Smell",
              "url": "https://www.theperfumesmell.com",
              "logo": "https://www.theperfumesmell.com/logo.png"
            }
          `}
        </script>
      </Helmet>
      <Outlet />
    </>
  )
}
export default Layout;