
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const { pathname, hash } = useLocation();

    const scrollToTop = () => {
        const scrollStep = window.pageYOffset / 50;

        const scrollAnimation = () => {
            const scrollTop = window.pageYOffset - scrollStep;
            if (window.pageYOffset <= 0) {
                return;
            }
            window.scroll(0, scrollTop);
            window.requestAnimationFrame(scrollAnimation);
        };
        scrollAnimation();
    };

    useEffect(() => {
        if (hash !== '#reviews') {
            console.log("scroll again");
            scrollToTop();
        }
    }, [pathname, hash]); 

    return null;
}

export default ScrollToTop;