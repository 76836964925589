import React from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import LeftSitebar from '../layout/LeftSitebar';
import ImgMasterCard from '../assets/img/mastercard_icon.png';







const MyCart = () => {
  return (
    <>
      <Header />
      <main>

        <section className="space-cls my-account-page">
          <div className="container">
            <div className="my-account-inner">
              <LeftSitebar />
              <div className="myaccount_r">
                <div className="my-card-sec">
                  <div className="card_left_wrap">
                    <h2 className="heading-type2">My Cards</h2>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <div className="mycard-cls">
                          <div className="card-title">
                            <div className="card-left">
                              <h5>Mastercard</h5>
                            </div>
                            <div className="card-right">
                              <div className="card_option">
                                <label className="custom_radio_b">
                                  <input type="radio" name="radio" />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="card-number">
                            <h2>0000 1111 2222 3333</h2>
                          </div>
                          <div className="card_valid">
                            <div className="card_left_valid">
                              <h5>Valid Thru</h5>
                              <h3>10/25</h3>
                            </div>
                            <div className="card_right_valid">
                              <span className="cvv-no">012</span>
                            </div>
                          </div>
                          <div className="card_type_name">
                            <div className="card-holder-name">
                              <h4>Peter Parker</h4>
                            </div>
                            <div className="card-type">
                              <img src={ImgMasterCard} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <div className="mycard-cls">
                          <div className="card-title">
                            <div className="card-left">
                              <h5>Mastercard</h5>
                            </div>
                            <div className="card-right">
                              <div className="card_option">
                                <label className="custom_radio_b">
                                  <input type="radio" name="radio" />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="card-number">
                            <h2>0000 1111 2222 3333</h2>
                          </div>
                          <div className="card_valid">
                            <div className="card_left_valid">
                              <h5>Valid Thru</h5>
                              <h3>10/25</h3>
                            </div>
                            <div className="card_right_valid">
                              <span className="cvv-no">012</span>
                            </div>
                          </div>
                          <div className="card_type_name">
                            <div className="card-holder-name">
                              <h4>Peter Parker</h4>
                            </div>
                            <div className="card-type">
                              <img src={ImgMasterCard} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="make_default">
                      <span>Make Default</span>
                      <div className="address-toggle">
                        <input type="checkbox" checked="" id="switch" /><label for="switch"></label>
                      </div>
                    </div>
                    <div className="add-new-card d-flex">
                      <a href="#" className="btn btn_primary" data-bs-toggle="modal" data-bs-target="#AddCard">Edit Card</a>
                      <a href="#" className="btn btn_primary ms-3" data-bs-toggle="modal" data-bs-target="#AddCard">Delete</a>
                    </div>
                  </div>
                  <div className="card_right_wrap">
                    <div className="add_new_card">
                      <h6>Add New Card</h6>
                      <form>
                        <div className="form-group">
                          <input type="text" name="name" className="form-control" placeholder="Card Holder Name" />
                        </div>
                        <div className="form-group">
                          <input type="text" name="number" className="form-control" placeholder="Card Number" />
                        </div>
                        <div className='form-group'>
                          <div className="row">
                            <div className="col-6 col-md-6">
                              <input type="text" name="" className="form-control" placeholder="03" />
                            </div>
                            <div className="col-6 col-md-6">
                              <input type="text" name="" className="form-control" placeholder="2032" />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <input type="text" name="cvv" className="form-control" placeholder="CVV" />
                        </div>
                        <div className="add_wrap d-flex">
                          <a href="#" className="btn btn_primary">Save</a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>
      <Footer />
    </>
  )
}
export default MyCart;