import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import { Link } from "react-router-dom";
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


import ImgCk from '../../assets/img/ck.png';
import ImgAx from '../../assets/img/ax.png';
import ImgPuma from '../../assets/img/puma.png';
import ImgmA1 from '../../assets/img/a1.png';
import ImgmA2 from '../../assets/img/a2.png';
import ImgmA3 from '../../assets/img/a3.png';
import ImgmA4 from '../../assets/img/a4.png';
import ImgmStar from '../../assets/img/star.png';
import ImgmFavorite from '../../assets/img/favorite.png';
import ImgmOutofstock from '../../assets/img/outofstock.png';
import ImgBestSeller from '../../assets/img/best-seller.png';
import ImgSearch from '../../assets/img/search.png';
import Banner from '../../view/Component/Banner';
import Product from '../../view/Component/Product';
import Currency from './Currency';


import LocalizedStrings from 'react-localization';
import localization from '../../localization';

import Modal from 'react-bootstrap/Modal';
import Imgcross from '../../assets/img/cross.png';
import { Button, Form } from 'react-bootstrap';
import { SelectGroup, ValidationForm } from "react-bootstrap4-form-validation";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

let strings = new LocalizedStrings(localization);

const CommanList = (Props) => {

    const formRef = React.createRef();
   const [modalIsOpen, setModalIsOpen] = useState(false);

   const setModalIsOpenToTrue = (id, attributes) => {
      setComman({ ...comman, product_id: id, attributes: attributes });
      // alert(id)
      setModalIsOpen(true)
   }

   const setModalIsOpenToFalse = () => {
      setModalIsOpen(false)
   }

   const showAddtoCart = async (e, id, attributes) => {
      e.preventDefault();
      if (attributes.length > 0) {
         setModalIsOpenToTrue(id, attributes);
      }
      else {
         setComman({ ...comman, product_id: id, attribute_value_id: null });
         addtoCart(id, null);
      }
   };

   const addtoCart = async (id, type) => {
      let path = apiPath.AddtoCart;
      var data = new FormData();
      data.append('product_id', id);
      data.append('qty', 1);
      if (type == null && comman.attribute_value_id != null) {
         data.append('product_attribute_values_id', comman.attribute_value_id);
      }
      else if (type == 'form') {
		const attributeValueId = document.querySelector('input[name="attribute_value_id"]:checked');
		if (attributeValueId != null && attributeValueId != "") {
			data.append('product_attribute_values_id', attributeValueId.value);
		}
      }

      let options = {
         method: 'POST',
         data: data
      }

      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            toast.success(result.data.message);
         }
         else {
            toast.error(result.data.message);
         }
         setModalIsOpenToFalse();
      }
   }

	const [currentLang, setCurrentLang] = useState(localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') : 'en');


	const options = {
		margin: 0,
		nav: true,
		dots: false,
		loop: true,
		responsive: {
			0: {
				items: 1,
				margin: 7.5
			},
			420: {
				items: 1
			},
			577: {
				items: 1
			},
			992: {
				items: 1
			}
		}
	};

	const newAriveOption = {
		margin: 15,
		nav: true,
		dots: false,
		loop: true,
		responsive: {
			0: {
				items: 2,
				margin: 7.5
			},
			420: {
				items: 2,
				margin: 7.5
			},
			577: {
				items: 3,
				margin: 7.5
			},
			767.98: {
				items: 4
			},
			992: {
				items: 5
			},
			1366: {
				items: 6
			},
			1480: {
				items: 7
			}
		}
	}

    const [comman, setComman] = useStateWithCallbackLazy({
        product_id: 0,
        attributes: [],
        attribute_value_id: null
     });

	const [commanData, setCommanData] = useState([]);

	const getComman = async () => {
		let path = apiPath.CommonApi;
		var data = new FormData();
		let options = {
			method: 'GET',
		}
		let result = await Http(path, options);
		if (result.status == 200) {
			setCommanData({ data: result.data.data })
		}

	}

	strings.setLanguage(currentLang);

	useEffect(() => {
		getComman();
	}, []);




	return (
		<>
			<section className="space-cls best-seller-sec">
				<div className="container">

					{commanData.data ? <Banner data={commanData.data.sale_banner} options={options} type="sale" /> : <Skeleton height={487} count={1} />}

				</div>
			</section>


			<section className="space-cls home-fragrance-prd-sec">
				<div className="container">
					<div className="title-main">
						{commanData.data && commanData.data.related_product != '' ? <h2 className="heading-type1">You May Also Like</h2> : ''}
					</div>
					{commanData.data ? <Product showAddtoCart={showAddtoCart} data={commanData.data.related_product} options={newAriveOption} /> : <Skeleton height={100} count={1} />}
				</div>
			</section>
			<section className="space-cls home-fragrance-prd-sec">
				<div className="container">
					<div className="title-main">
						{commanData.data && commanData.data.most_sold != '' ? <h2 className="heading-type1">{strings.mostPickedProduct}</h2> : ''}
					</div>
					{commanData.data ? <Product showAddtoCart={showAddtoCart} data={commanData.data.most_sold} options={newAriveOption} /> : <Skeleton height={100} count={1} />}

				</div>
			</section>
            {modalIsOpen ? <>
            <Modal show={true} animation={true} size="lg" backdrop="static" keyboard={false} centered >
               <Modal.Header>
                  <h5 className="modal-title" id="exampleModalLabe111l">{strings.attributes}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                     <img src={Imgcross} onClick={setModalIsOpenToFalse} />
                  </button>
               </Modal.Header>
               <Modal.Body className='modal-body'>
                  <div className="continue-btn review_modal">
                     <ValidationForm ref={formRef} >
                        <div className="popup-cont">
                           <Form.Group className="form-group" controlId="form-group">
						   		<div className="prd-size">
									<div className="prd_size_label">
										{comman.attributes[0].attribute_values && comman.attributes[0].attribute_values.map((option, i) => (
											<label className="custom_radio_b" key={option.id}>
													<input
														type="radio"
														name="attribute_value_id"
														value={option.id}
														defaultChecked={i === 0}
													/>
													<span className="checkmark">{option.value}</span>
											</label>
										))}
									</div>
								</div>
                           </Form.Group>
                           <div className="login-btn">
                              <Button onClick={(e) => addtoCart(comman.product_id, 'form')} type="button" className="btn btn_primary">{strings.add_to_bag}</Button>
                           </div>
                        </div>
                     </ValidationForm>
                  </div>
               </Modal.Body>
            </Modal></> : ""}
		</>

	)
}
export default CommanList;