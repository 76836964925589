import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ImgTick from '../assets/img/tick.png';
import { Link, useParams } from "react-router-dom";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import toast from 'react-hot-toast';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";

import { userLogin } from "../actions/index";
import { connect } from "react-redux";
import { Button, Form } from 'react-bootstrap';
import LocalizedStrings from "react-localization";
import localization from "../localization";
import { Rating } from 'react-simple-star-rating';
import { TextInput, ValidationForm, FileInput } from "react-bootstrap4-form-validation";
import Modal from 'react-bootstrap/Modal';
import Imgcross from '../assets/img/cross.png';
import NoImage from '../assets/img/noimage.png';
import { useNavigate } from 'react-router-dom';

let strings = new LocalizedStrings(localization);


const mapStateToProps = state => {
   return { user: state.user };
};

const mapDispatchToProps = dispatch => {
   return {
      userLogin: user => dispatch(userLogin(user))
   };
}

const Thankyou = (Props) => {
  const navigate = useNavigate();
  const formRef = React.createRef();
  const [orderDetail, setOrderDetail] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [comman, setComman] = useStateWithCallbackLazy({
    type: "all",
    product_id: 0,
    reOrder: false,
    productType: "Product"
  });
  const [apiCalled, setApiCalled] = useState(false);

  const [common, setCommon] = useState({
    image: ''
  });
  const [file, setFile] = useState();

   const params = useParams();
   const handleChangeImg = (e) => {
    setCommon({ ...common, [e.target.name]: e.target.files[0] })
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const setModalIsOpenToTrue = (e, id, type) => {
    setComman({ ...comman, product_id: id, productType: type })
    setCommon({ ...common, image: null })
    setFile(null);
    // alert(id)
    setModalIsOpen(true)
  }
  const [rating, setRating] = useState(0)
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false)
  }

  const [user, setUser] = useState(
    Object.keys(Props.user).length > 0 ? Props.user : null
  );

   const [currentLang, setCurrentLang] = useState(
      localStorage.getItem("current_lang")
         ? localStorage.getItem("current_lang")
         : "en"
   );
   strings.setLanguage(currentLang);

   const registerSubmit = async (e, formData, inputs) => {
    e.preventDefault();

    if (rating > 0) {
      let path = apiPath.rateProduct;

      var data = new FormData();
      if (common.image) {
        data.append('image', common.image);
      }
      data.append('rating', rating ? rating : 1);
      data.append('review', formData.review);
      data.append('product_id', comman ? comman.product_id : 0);
      data.append('type', comman ? comman.productType : "Product");

      let options = {
        method: 'POST',
        data: data
      }
      let result = await Http(path, options);
      if (result.status === 200) {
        if (result.data.status) {

          toast.success(result.data.message);
          setModalIsOpen(false);
          const orderId = params.orderId;
          getOrderDetail(orderId.split('-')[1]);
        } else {
          toast.error(result.data.message);
        }
      } else {
        if(result.statusText != null) {
          toast.error(result.statusText);
        }
      }
    }
    else {
      toast.error(strings.plase_select_rating_also);
    }
  }
  const registerErrorSubmit = (e, formData, inputs) => {
    e.preventDefault();
    if (rating > 0.5) {

    }
  }
  const handleRating = (rate) => {
    setRating(rate)

    // other logic
  }

  // Optinal callback functions
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value, index) => console.log(value, index)
  const handleChange = (value, data, event, formattedValue) => {}

  const getOrderDetail = async (id) => {
    let path = apiPath.orderDetails;
    var data = new FormData();
    data.append("random_order_id", id)
    let options = {
      method: 'Post',
      data: data
    }
    let result = await Http(path, options);
    if (result.status === 200) {
      if (result.data.status) {
        setOrderDetail({ data: result.data.data });
        if (!apiCalled) {
          setApiCalled(true);
          loadGoogleOptInScript(id, result.data.data);
        }
      } else {
        toast.error(result.data.message);
      }
    }
  }

  const loadGoogleOptInScript = (orderId, orderData) => {
    const script = document.createElement('script');
    script.src = "https://apis.google.com/js/platform.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    let estimatedDeliveryDate;
    if (orderData.created_at) {
      const deliveryDate = new Date(new Date(orderData.created_at).getTime() + 2 * 24 * 60 * 60 * 1000);
      estimatedDeliveryDate = deliveryDate.toISOString().slice(0, 10);
    } else {
      const currentDate = new Date();
      const deliveryDate = new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000);
      estimatedDeliveryDate = deliveryDate.toISOString().slice(0, 10);
    }

    script.onload = () => {
      window.gapi.load('surveyoptin', function() {
        window.gapi.surveyoptin.render({
          "merchant_id": 323161519,
          "order_id": orderId,
          "email": orderData.address.email ?? user.details.email,
          "delivery_country": orderData.address.country.sortname ?? "KW",
          "estimated_delivery_date": estimatedDeliveryDate
        });
      });
    };
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const orderId = params.orderId.split('-')[1];
    getOrderDetail(orderId);
  }, [params]); 

   return (
      <>
         {/* {user ? user.details.temp_user == 1 ? <Navigate to="/" replace={true} /> : '' : <Navigate to="/" replace={true} />} */}
         <Header />
         <main>
            <section className="thankyou-sec">
               <div className="container">
                  <div className="thankyou_sec_in">
                     <img src={ImgTick} alt="check icon" />
                     <h1>{strings.thankyou}</h1>
                     <p>{strings.your_order} :<b> {params.orderId}</b> {strings.success_place_with_us}</p>
                     <div className="btn_thank">
                        <Link className="btn btn_primary" to="/">{strings.back_to_home}</Link>
                     </div>
                  </div>
               </div>
            </section>
            <section className="space-cls my-account-page">
              <div className="container">
                <div className="my-account-inner">
                  <div className="myaccount_r">
                    <h2 className="heading-type2">{strings.orderRatings}</h2>
                    <div className="order-page-dtl">
                      <div className="order-list">
                        <div className="order_wrap">
                          {orderDetail ? orderDetail.data ? orderDetail.data.items.length > 0 ? orderDetail.data.items.map((element) =>

                            <div key={element.id} className="order-body">
                              <div className="cart-product">
                                <div className="cart-prd-img">
                                  <img src={element.product_image} alt="Cart Product" />
                                </div>
                                <div className="card-prd-cont">
                                  <Link to={element.type == 'Product' ? '/product-detail/' + element.products.slug : '/product-combo-detail/' + element.products.slug} target="_blank">

                                    <div className="arrival-title">
                                      <h3>
                                        {element.product_name}
                                        {element.products.is_sample && <span className="sample-tag">{strings.sample}</span>}
                                      </h3>
                                      {element ? element.type == 'Product' ?
                                        <p>{element ? element.products ? element.products.brands ? element.products.brands.name : '' : '' : ''}</p> : <p>{strings.combo}</p> : ""}
                                    </div>
                                    <div className="prd-quantity">
                                      <span>{strings.qty} x {element ? element.qty : ''}</span>
                                    </div>
                                  </Link>
                                </div>
                                <div className="cart_prd_right">
                                  <div className="item_total_price">
                                    <h3>
                                      {orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {element ? (element.amount*orderDetail.data.currency_value).toFixed(2) : 0} 
                                    </h3>
                                    {orderDetail ? orderDetail.data ? element.is_rate == 0 ?
                                      <div className="order-actions d-flex">
                                        <Button onClick={(e) => setModalIsOpenToTrue(e, element.product_id, element.type)} className="btn btn_primary ">{strings.rateus}</Button>

                                      </div> : null : null : null
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null : null : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
         </main>
         <Footer />
         {
          modalIsOpen ? <>
            <Modal show={true} animation={true} size="lg" backdrop="static" keyboard={false} centered >

              <Modal.Header>
                <h5 className="modal-title" id="exampleModalLabe111l">Review</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                  <img src={Imgcross} onClick={setModalIsOpenToFalse} />
                </button>
              </Modal.Header>
              <Modal.Body>
                {/* <p className="signin-cont">Sign in to grab special offers, extra reward points &amp; more benefits!</p> */}
                <div className="continue-btn review_modal">
                  <ValidationForm onSubmit={registerSubmit} onErrorSubmit={registerErrorSubmit} ref={formRef} >

                    <div className="popup-cont">
                      <Form.Group className="form-group" controlId="form-group">
                        <Form.Label>Rate</Form.Label>
                        <Rating
                          transition
                          allowFraction
                          showTooltip
                          onClick={handleRating}
                          onPointerEnter={onPointerEnter}
                          onPointerLeave={onPointerLeave}
                          onPointerMove={onPointerMove}
                          tooltipDefaultText='Rate'
                        /* Available Props */
                        />
                        {/* <TextInput type="number" name="rating" id="rate" required
                          placeholder="Enter the rate"
                          errorMessage="Please enter the rate"
                          onChange={handleChange}
                        /> */}
                      </Form.Group>
                      <Form.Group className="form-group" controlId="form-group">
                        <Form.Label>Review</Form.Label>
                        <TextInput name="review" id="review"
                          placeholder="Enter the review"
                          onChange={handleChange}
                        />
                        <div className='rating_image'>
                          <div className="rating_img_upload profile_img">
                            <img src={file ? file : NoImage} alt="Rating Image" />
                          </div>
                          <div className="upload_cls">
                            <div className="file_input">
                              <FileInput name="image" id="image"
                                fileType={["jpg", "png", "gif", "webp"]}
                                maxFileSize="5120 kb"
                                errorMessage={
                                  {
                                    required: strings.plz_upload_file,
                                    fileType: "Only jpg,png,gif and webp files is allowed",
                                    maxFileSize: strings.max_file_size
                                  }
                                }
                                onChange={handleChangeImg}
                              />
                              <span for="image">{strings.upload_rating_image}</span>
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                      <div className="login-btn">
                        <Button type="submit" className="btn btn_primary">Submit</Button>
                      </div>
                      {/*<div className="already_cls">
                                  <p>If you already have an account? <Link to="#" onClick={openLogin}>Sign In</Link></p>
                                                              </div>*/}
                    </div>
                  </ValidationForm>

                </div>
              </Modal.Body>

            </Modal></> : ""
        }
      </>
   )
}
export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);