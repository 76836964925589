import React from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import OwlCarousel from 'react-owl-carousel3';
import ImgBestSeller from '../assets/img/best-seller.png';

import ImgLocationIcon from '../assets/img/location_icon.png';
import ImgCountryFlag from '../assets/img/country_flag.png';

const options = {
	margin: 0,
	nav:true,
	dots:false,
	loop: true,
	responsive: {
	  0: {
		items: 1,
		margin: 7.5
	  },
	  420: {
		items: 1
	  },
	  577: {
		items: 1
	  },
	  992: {
		items: 1
	  }
	}
};

const newAriveOption = {
	margin: 15,
	nav:true,
	dots:false,
	loop: true,
	responsive: {
	  0: {
		items: 1,
		margin: 7.5
	  },
	  420: {
		items: 2.5,
		margin: 7.5
	  },
	   577: {
		items: 3,
		margin: 7.5
	  },
	  767.98: {
		items: 4
	  },
	  992: {
		items: 5
	  },
	  1366: {
		items: 7
	  }
	}
}


const Address = () =>{
    return (
        <>
        <Header/>
       	<main>
	
		   <section className="space-cls address-page">
            <div className="container">
               <div className="inner-tit">
                  <h3>Available Address</h3>
               </div>
               <div className="address_wrap">
                  <div className="row">
                     <div className="col-md-6 bg-gray">
                        <div className="address_select">
                           <div className="address_inner_select"> 
                              <label className="custom_radio_b">
                                 <input type="radio" checked="checked" name="radio"/>
                                 <span className="checkmark"></span>
                                 <span className="address_tit">Home</span>
                                 <span className="address_tag">123, lorem ipsum, dummy text</span>
                              </label>
                              <a href="#">Edit</a>
                           </div>
                           <div className="address_inner_select"> 
                              <label className="custom_radio_b">
                                 <input type="radio" name="radio"/>
                                 <span className="checkmark"></span>
                                 <span className="address_tit">Office</span>
                                 <span className="address_tag">123, lorem ipsum, dummy text</span>
                              </label>
                              <a href="#">Edit</a>
                           </div>
                           
                        </div>
                     </div>
                     <div className="col-md-6 light-gray">
                        <div className="add-address-tit">
                           <h4>Add Address</h4>
                           <div className="country_wrap">
                              <span className="country_label">Country: </span>
                              <div className="country_name">
                                 <span><img src={ImgCountryFlag} alt="Flag"/></span>
                                 <span>Kuwait</span>
                              </div>
                           </div>
                        </div>
                        <form className="address_form">
                           <div className="row">
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <input type="text" name="" placeholder="Area" className="form-control"/>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <input type="text" name="" placeholder="Block" className="form-control"/>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <input type="text" name="" placeholder="Street" className="form-control"/>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <input type="text" name="" placeholder="Avenue" className="form-control"/>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <input type="text" name="" placeholder="House no." className="form-control"/>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <input type="text" name="" placeholder="Pin Code" className="form-control"/>
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="form-group">
                                    <textarea id="" name="" className="form-control" placeholder="Note"></textarea>
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="form-group custom_slt">
                                    <label className="custom_radio_b">
                                       <input type="radio" name="radio"/>
                                       <span className="checkmark"></span>Home 
                                    </label>
                                    <label className="custom_radio_b">
                                       <input type="radio" name="radio"/>
                                       <span className="checkmark"></span>Office
                                    </label>
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="form-group">
                                    <input type="text" name="" placeholder="D.O.B." className="form-control"/>
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="form-group form-submit">
                                    <a href="#" className="btn btn_primary">Submit</a>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="address-toggle">
                                    <input type="checkbox" checked="" id="switch" />
									<label for="switch"></label>
									<span>Use as a default Address</span>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="locate-wrap">
                                    <a href="#"><span className="location-icon">
										<img src={ImgLocationIcon}/>
										</span>Locate Yourself</a>
                                 </div>
                              </div>
                           </div>
                        </form>     
                     </div>
                  </div>
               </div>
            </div>
         </section>

        <section className="space-cls best-seller-sec">
          <div className="container">
          <OwlCarousel className="owl-carousel  owl-theme" {...options}  > 
              <div className="item">
                <div className="banner-seller">
                  <a href="#">
                    <img src={ImgBestSeller}/>
                  </a>
                  <div className="brand-cont">
                      <p className="brand-tag">Best Seller Fragrance</p>
                      <p>You Can Take Maximum Discount Here</p>
                    </div>
                </div>
              </div>
              <div className="item">
                <div className="banner-seller">
                  <a href="#">
                    <img src={ImgBestSeller}/>
                  </a>
                  <div className="brand-cont">
                      <p className="brand-tag">Best Seller Fragrance</p>
                      <p>You Can Take Maximum Discount Here</p>
                    </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </section>



       	</main>
        <Footer/>
        </>
    )
}
export default Address;