import React, { useState, useEffect } from 'react';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Helmet } from 'react-helmet';

import Header from '../layout/Header';
import Footer from '../layout/Footer';
import BrandList from '../view/Component/BrandList';
import CommanList from '../view/Component/CommanList';

import LocalizedStrings from 'react-localization';
import localizations from "../localization";
let strings = new LocalizedStrings(localizations);



const options = {
	margin: 0,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 1,
			margin: 7.5
		},
		420: {
			items: 1
		},
		577: {
			items: 1
		},
		992: {
			items: 1
		}
	}
};

const newAriveOption = {
	margin: 15,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 1,
			margin: 7.5
		},
		420: {
			items: 2.5,
			margin: 7.5
		},
		577: {
			items: 3,
			margin: 7.5
		},
		767.98: {
			items: 4
		},
		992: {
			items: 5
		},
		1366: {
			items: 7
		}
	}
}


const Brand = () => {

	const [brand, setBrand] = useState([]);
	const [fiterBrand, setFiterBrand] = useState([]);
	const [getAlphabet, setAlphabet] = useState();
	const [comman, setComman] = useState({
		page: 1,
		last_page: 0,
		showLoad: 'showCl'
	});



	const [currentLang, setCurrentLang] = useState(
		localStorage.getItem("current_lang")
			? localStorage.getItem("current_lang")
			: "en"
	);
	strings.setLanguage(currentLang);

	const getBrand = async (page) => {
		let path = apiPath.brands;
		var data = new FormData();
		data.append('page', page);
		let options = {
			method: 'POST',
			//				headers: { 'language': this.state.language },
			data: data
		}

		let result = await Http(path, options);
		if (result.status === 200) {
			if (result.data) {
				let resultJson = result.data.result;
				if (page == 1) {

					setBrand({
						data: result.data.data.data,
					})
					setFiterBrand({
						data: result.data.data.data,
					})
					//alert(result.data.data.last_page);
					setComman({ ...comman, last_page: result.data.data.last_page });
				}
				else {
					setBrand({
						data: brand.data.concat(result.data.data.data)
					})
				}
				if (page === comman.last_page) {
					setComman({ ...comman, showLoad: 'hideCl' });
				}
			} else {
				toast.error(result.data.message);
			}
		} else {
			if(result.statusText != null) {
				toast.error(result.statusText);
			}
		}
	}
	const onAlphabetClick = async (e) => {
		// alert(e.target.value)
		await setAlphabet(e.target.value)
		// filterItems(brand.data)
	}
	const prepareAlphabets = () => {
		let result = [];
		// alert("hello");
		for (let i = 65; i < 91; i++) {
			result.push(
				<button type="button" className={getAlphabet == String.fromCharCode(i) ? "active" : ''} key={i} onClick={onAlphabetClick} value={String.fromCharCode(i)} >{String.fromCharCode(i)}</button>
			)
		}
		return result;
	}
	// const elementContainsSearchString = (element) => (searchInput ? element.name.toLowerCase().includes(searchInput.toLowerCase()) : false);
	const filterItems = (itemList) => {
		let result = [];
		// const { searchInput, alphabet } = this.state;
		// alert(getAlphabet);
		if (itemList && (getAlphabet)) {
			result = itemList.filter((element) => (element.name.charAt(0).toLowerCase() === getAlphabet.toLowerCase()));
			// elementContainsSearchString(element));
		} else {
			result = itemList || [];
		}
		// result = result.map((item) => (<li>{item.name}</li>))
		setFiterBrand({
			data: result,
		})
		// console.log(result)
		// return result;
	}

	useEffect(() => {
		filterItems(brand.data)
	}, [getAlphabet]);

	useEffect(() => {
		getBrand(1);
	}, []);

	const loadMore = (data) => {
		var page = comman.page
		page = page + 1;
		setComman({ ...comman, page: page });
		getBrand(page);
	}

	const meta = {
        en: {
            title: "Top Kuwait Perfume Brands | Original Perfumes in Kuwait",
            description: "Finest Kuwait perfume brands offering authentic, original fragrances. Shop premium perfumes in Kuwait, crafted for a lasting impression.",
			keyword: "Brands"
        },
        ar: {
            title: "افضل ماركات العطور الكويتية | العطور الأصلية في الكويت",
            description: "أرقى ماركات العطور الكويتية تقدم عطور أصلية وأصيلة. تسوق العطور الفاخرة في الكويت، المصممة لترك انطباع دائم",
			keyword: "العلامات التجارية"
        }
    };

	return (
		<>
			<Header />
			<Helmet>
                <title>{meta[currentLang].title}</title>
                <meta name="description" content={meta[currentLang].description} />
				<meta name="keywords" content={meta[currentLang].keyword} />
            </Helmet>
			<main>
				<section className="space-cls cart-page">
					<div className="container">
						<div className="inner-tit">
							<h3>{strings.top_brands}</h3>
							<div className='alphabets'>
								<button type="button" className={getAlphabet ? "" : 'active'} onClick={onAlphabetClick}  >All</button>
								{prepareAlphabets()}
							</div>
							{/*<div className="search-wrap">
                     <div className="form-group">
                        <div className="input-group">
                           <div className="search-icon">
                              <img src={ImgSearch} alt="Search"/>
                           </div>
                           <input type="text" name="" className="form-control" placeholder="Search for Perfume Smell"/>
                        </div>
                     </div>
                  </div>*/}
						</div>

						<div className="space-cls category-sec">
							<div className="row">

								{fiterBrand.data ? <BrandList data={fiterBrand.data} key="" /> : <Skeleton height={10} count={10} />}
							</div>

							{/*<div className={"load-more d-flex justify-content-center mt-2 " +comman.showLoad }>
                    <button type="button" className="btn btn_primary" onClick={loadMore}>{strings.loadMore}</button>
				</div>*/}
						</div>
					</div>
				</section>
				<CommanList />
			</main>
			<Footer />
		</>
	)
}
export default Brand;