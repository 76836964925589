import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Imgcross from '../../assets/img/cross.png';
import OtpInput from 'react-otp-input';
import OtpTimer from 'otp-timer';
import toast, { Toaster } from 'react-hot-toast';
import { internalIpV6, internalIpV4 } from '../../services/getIp';

import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";

import { userLogin } from "../../actions/index";
import { connect } from "react-redux";

import { ValidationForm, TextInput, TextInputGroup, FileInput, SelectGroup, Checkbox } from "react-bootstrap4-form-validation";
import Login from '../Modal/Login';
import validator from 'validator'


import LocalizedStrings from 'react-localization';
import localization from '../../localization';
let strings = new LocalizedStrings(localization);

const mapStateToProps = state => {
  return { user: state.user };

};

const mapDispatchToProps = dispatch => {
  return {
    userLogin: user => dispatch(userLogin(user))
  };
};

const OtpVerifyForgetPassword = (Props) => {

  const [showOtp, setShowOtp] = useState(false);
  const [getRegister, setRegister] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [getOtp, setOtp] = useState({
    otp: '',
    hasErrored: false
  });

  const otpClose = () => setShowOtp(false);
  useEffect(() => {
    setShowOtp(Props.data);
    setRegister(Props.userData)
  }, [Props]);


  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
      ? localStorage.getItem("current_lang")
      : "en"
  );
  strings.setLanguage(currentLang);


  const handleOtpSubmit = async (e, formData, inputs) => {
    e.preventDefault();
    if (validate()) {

      console.log(formData)
      let path = apiPath.resetPassword;
      var data = new FormData();
      // data.append('country_code', getRegister.country_code);
      // data.append('mobile', getRegister.mobile);
      data.append('email', getRegister.email);
      data.append('otp', getOtp.otp);
      data.append('device_type', 'Website');
      data.append('device_token', await internalIpV4());
      data.append('new_password', formData.password);
      data.append('confirm_password', formData.confirm_password);
      let options = {
        method: 'POST',
        data: data
      }

      let result = await Http(path, options);
      if (result.status === 200) {
        if (result.data.status) {
          setShowOtp(false);
          setShowLogin(true)
          toast.success(result.data.message);
          // window.location.reload();
        } else {
          toast.error(result.data.message);
        }
      } else {
        if(result.statusText != null) {
          toast.error(result.statusText);
        }
      }
    }
  }


  const validate = () => {
    let isValid = true;
    if (getOtp.otp == null || getOtp.otp == '') {
      toast.error("OTP is required!");
      isValid = false;
    }
    return isValid;
  }


  const handleChange = (value, data, event, formattedValue) => {
    setOtp({ ...getOtp, otp: value });
  }

  const handleResendOtp = async () => {
    let path = apiPath.resetPassword;
    var data = new FormData();
    data.append('email', getRegister.email);

    // data.append('mobile', getRegister.mobile);
    data.append('device_type', 'Website');
    data.append('device_token', await internalIpV4());
    let options = {
      method: 'POST',
      data: data
    }

    let result = await Http(path, options);
    if (result.status === 200) {
      if (result.data.status) {
        toast.success(result.data.message);
        //Props.userLogin({token:Props.user.token,details:result.data.data});		         
      } else {
        toast.error(result.data.message);
      }
    } else {
      if(result.statusText != null) {
        toast.error(result.statusText);
      }
    }

  }






  return (
    <>
      {showLogin ? <Login data={showLogin} /> : ''}
      <Modal show={showOtp} onHide={otpClose} animation={true} size="lg" backdrop="static" keyboard={false} centered >
        <Modal.Header >
          <h5 className="modal-title" id="exampleModalLabel">{strings.OTP}</h5>
          <button type="button" className="btn-close" onClick={otpClose}>
            <img src={Imgcross} />
          </button>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <ValidationForm onSubmit={handleOtpSubmit} >
            <div className="popup-cont">

              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label> <h4>{strings.verification_code}</h4></Form.Label>
                <Form.Text className='d-flex mb-3'><p>{strings.which_one_send} "{getRegister ? getRegister.email : ''}" {strings.email}</p></Form.Text>
                <div className="form-group opt_screen">
                  <OtpInput autoFocus required
                    value={getOtp.otp}
                    onChange={handleChange}
                    numInputs={4}
                    hasErrored={getOtp.hasErrored}
                    isInputNum={true}
                    // containerStyle={{'justify-content':'center'}}
                    errorMessage={{ required: strings.enter_otp }}
                  />

                </div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Enter Password</Form.Label>
                <TextInputGroup type="password" name="password" placeholder="Password" required />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Enter Confirm Password</Form.Label>
                <TextInputGroup type="password" name="confirm_password" placeholder="Password" required />
              </Form.Group>
              <div className="login-btn">
                <Button type="submit" className="btn btn_primary">{strings.continue}</Button>
              </div>
              <div className="already_cls">
                <p>{strings.dontReceive_otp} <OtpTimer seconds={60} ButtonText='Resend code' className="btn btn_primary" minutes={0} resend={handleResendOtp} /></p>
              </div>
            </div>
          </ValidationForm>
        </Modal.Body>
      </Modal>
    </>

  )
}
export default connect(mapStateToProps, mapDispatchToProps)(OtpVerifyForgetPassword);