import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import store from "./store/index";
let dir = localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') === 'ar' ? 'rtl' : 'ltr' : ''
let lang = localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') : 'en'


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  
  <Provider store={store}>
  <App />
</Provider>
);  
document.getElementsByTagName('html')[0].setAttribute("dir", dir);
document.getElementsByTagName('html')[0].setAttribute("lang", lang);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
