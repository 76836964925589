import React, { useEffect, useState } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import LeftSitebar from '../layout/LeftSitebar';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import noOrder from '../assets/img/order-not-found.png';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Button, Form } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import RatingModal from './Modal/RatingModal';
import Modal from 'react-bootstrap/Modal';
import Imgcross from '../assets/img/cross.png';
import { TextInput, ValidationForm, FileInput } from "react-bootstrap4-form-validation";
import { Rating } from 'react-simple-star-rating';

import { userLogin } from "../actions/index";
import { connect } from "react-redux";

import OrderListing from './Component/OrderListing';
import NoImage from '../assets/img/noimage.png';



import LocalizedStrings from "react-localization";
import localization from "../localization";
let strings = new LocalizedStrings(localization);


const mapStateToProps = (state) => {
  return { user: state.user };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (user) => dispatch(userLogin(user)),
  };
};

const Order = (Props) => {
  const formRef = React.createRef();
  const [order, setOrder] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [comman, setComman] = useStateWithCallbackLazy({
    page: 1,
    last_page: 0,
    type: "all",
    product_id: 0,
    reOrder: false,
    productType: "Product"
  });

  const [common, setCommon] = useState({
    image: ''
  });
  const [file, setFile] = useState();

  const [rating, setRating] = useState(0);

  const [user, setUser] = useState(
    Object.keys(Props.user).length > 0 ? Props.user : null
  );

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialType = queryParams.get('type') || 'all';
  const [currentType, setCurrentType] = useState(initialType);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
      ? localStorage.getItem("current_lang")
      : "en"
  );
  strings.setLanguage(currentLang);

  // Catch Rating value
  const handleRating = (rate) => {
    // alert(rate)
    setRating(rate)

    // other logic
  }

  const handleChangeImg = (e) => {
    setCommon({ ...common, [e.target.name]: e.target.files[0] })
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  // Optinal callback functions
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value, index) => console.log(value, index)

  const setModalIsOpenToTrue = (e, id, type) => {
    setComman({ ...comman, product_id: id, productType: type })
    setCommon({ ...common, image: null })
    setFile(null);
    // alert(id)
    setModalIsOpen(true)
  }

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false)
  }
  const getOrder = async (value) => {
    console.log(value, "value")
    let path = apiPath.getOrder;
    var data = new FormData();
    data.append('type', value == 1 ? "all" : value);
    let options = {
      method: 'POST',
      //				headers: { 'language': this.state.language },
      data: data
    }

    let result = await Http(path, options);
    if (result.status === 200) {
      if (result.data) {
        let resultJson = result.data;
        if (resultJson.status) {
          setOrder({
            data: result.data.data,
          })
        }
        else {
          setOrder({
            data: [],
          })
        }
      } else {
        toast.error(result.data.message);
      }
    } else {
      if(result.statusText != null) {
        toast.error(result.statusText);
      }
    }
  }
  const setOrderType = async (e, type, value) => {
    // setComman({ ...comman, type: value }, () => {
    //   getOrder(value);
    // });
    setCurrentType(value);
    navigate(`?${type}=${value}`);
  }
  const rateProduct = async (e, value) => {
    // alert(value, "value")
    setModalIsOpen(true);
  }
  const reOrder = async (e, value, type) => {
    let path = apiPath.reorder;
    var data = new FormData();
    data.append('order_id', value);
    let options = {
      method: 'POST',
      //				headers: { 'language': this.state.language },
      data: data
    }

    let result = await Http(path, options);
    if (result.status === 200) {
      if (result.data) {
        let resultJson = result.data;
        if (resultJson.status) {
          toast.success(result.data.message);
          setComman({ ...comman, reOrder: true })


          // getOrder("all")
        }
        //  getOrder("all")
      } else {
        toast.error(result.data.message);
      }
    } else {
      if(result.statusText != null) {
        toast.error(result.statusText);
      }
    }
  }
  const cancelOrder = async (e, value, type) => {
    if (window.confirm(strings.are_you_sure_you_want_to_cancel_order) == true) {
      let path = apiPath.cancelOrder;
      var data = new FormData();
      data.append('order_id', value);
      let options = {
        method: 'POST',
        //				headers: { 'language': this.state.language },
        data: data
      }
      let result = await Http(path, options);
      if (result.status === 200) {
        if (result.data) {
          let resultJson = result.data;
          if (resultJson.status) {
            getOrder(type)
          }
          getOrder(type)
        } else {
          toast.error(result.data.message);
        }
      } else {
        if(result.statusText != null) {
          toast.error(result.statusText);
        }
      }
    }
  }
  const registerSubmit = async (e, formData, inputs) => {
    e.preventDefault();
    // console.log(formData);
    // alert(formData)

    if (rating > 0) {
      let path = apiPath.rateProduct;

      var data = new FormData();
      if (common.image) {
        data.append('image', common.image);
      }
      data.append('rating', rating ? rating : 1);
      data.append('review', formData.review);
      data.append('product_id', comman ? comman.product_id : 0);
      data.append('type', comman ? comman.productType : "Product");

      let options = {
        method: 'POST',
        data: data
      }
      let result = await Http(path, options);
      if (result.status === 200) {
        if (result.data.status) {

          toast.success(result.data.message);
          setModalIsOpenToFalse()
          getOrder(1)
        } else {
          toast.error(result.data.message);
        }
      } else {
        if(result.statusText != null) {
          toast.error(result.statusText);
        }
      }
    }
    else {
      toast.error(strings.plase_select_rating_also);
    }
  }
  const registerErrorSubmit = (e, formData, inputs) => {
    e.preventDefault();
  }
  const handleChange = (value, data, event, formattedValue) => {

  }

  const tabMapping = {
    all: 0,
    pending: 1,
    Complete: 2,
    Cancel: 3
  };

  useEffect(() => {
    // Fetch orders based on initial tab
    getOrder(currentType);
  }, [currentType]);

  return (
    <>
      {user ? (
        user.details.temp_user == 1 ? (
          <Navigate to="/" replace={true} />
        ) : (
          ""
        )
      ) : (
        <Navigate to="/" replace={true} />
      )}
      {comman.reOrder ? <Navigate to={'/cart'} data={comman} replace={true} /> : ''}
      <Header />
      <main>
        <section className="space-cls my-account-page">
          <div className="container">
            <div className="my-account-inner">

              <LeftSitebar />
              <div className="myaccount_r">
                <h2 className="heading-type2">{strings.myOrder}</h2>
                <div className="order-page">
                  <div className="accordion-description">
                    <div className="mobile-scroll">
                      <Tabs selectedIndex={tabMapping[currentType]}>
                      <TabList className="nav nav-tabs">
                        <Tab>
                            <button
                                className={`nav-link ${currentType === 'all' ? 'active' : ''}`}
                                id="nav-all-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-all"
                                type="button"
                                role="tab"
                                aria-controls="nav-all"
                                onClick={(e) => setOrderType(e, 'type', 'all')}
                                aria-selected={currentType === 'all'}
                            >
                                {strings.all_orders}
                            </button>
                        </Tab>
                        <Tab>
                            <button
                                className={`nav-link ${currentType === 'pending' ? 'active' : ''}`}
                                id="nav-new-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-new"
                                type="button"
                                role="tab"
                                aria-controls="nav-new"
                                onClick={(e) => setOrderType(e, 'type', 'pending')}
                                aria-selected={currentType === 'pending'}
                            >
                                {strings.new_orders}
                            </button>
                        </Tab>
                        <Tab>
                            <button
                                className={`nav-link ${currentType === 'Complete' ? 'active' : ''}`}
                                id="nav-complete-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-complete"
                                type="button"
                                role="tab"
                                aria-controls="nav-complete"
                                onClick={(e) => setOrderType(e, 'type', 'Complete')}
                                aria-selected={currentType === 'Complete'}
                            >
                                {strings.completed_orders}
                            </button>
                        </Tab>
                        <Tab>
                            <button
                                className={`nav-link ${currentType === 'Cancel' ? 'active' : ''}`}
                                id="nav-cancel-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-cancel"
                                type="button"
                                role="tab"
                                aria-controls="nav-cancel"
                                onClick={(e) => setOrderType(e, 'type', 'Cancel')}
                                aria-selected={currentType === 'Cancel'}
                            >
                                {strings.cancel_order}
                            </button>
                        </Tab>
                    </TabList>
                        <div className='accordion-inner'>
                          <TabPanel>
                            {order.data ? <OrderListing data={order.data} type="all" cancelOrder={cancelOrder} reOrder={reOrder} setModalIsOpenToTrue={setModalIsOpenToTrue} /> : ''}
                          </TabPanel>

                          <TabPanel>
                            {order.data ? <OrderListing data={order.data} type="pending" cancelOrder={cancelOrder} reOrder={reOrder} setModalIsOpenToTrue={setModalIsOpenToTrue} /> : ''}
                          </TabPanel>

                          <TabPanel>
                            {order.data ? <OrderListing data={order.data} type="Complete" cancelOrder={cancelOrder} reOrder={reOrder} setModalIsOpenToTrue={setModalIsOpenToTrue} /> : ''}
                          </TabPanel>

                          <TabPanel>
                            {order.data ? <OrderListing data={order.data} type="Cancel" cancelOrder={cancelOrder} reOrder={reOrder} setModalIsOpenToTrue={setModalIsOpenToTrue} /> : ''}
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

      </main >

      <Footer />
      {modalIsOpen ? <>
        <Modal show={true} animation={true} size="lg" backdrop="static" keyboard={false} centered >

          <Modal.Header>
            <h5 className="modal-title" id="exampleModalLabe111l">Review</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <img src={Imgcross} onClick={setModalIsOpenToFalse} />
            </button>
          </Modal.Header>
          <Modal.Body className='modal-body'>

            {/* <p className="signin-cont">Sign in to grab special offers, extra reward points &amp; more benefits!</p> */}
            <div className="continue-btn review_modal">
              <ValidationForm onSubmit={registerSubmit} onErrorSubmit={registerErrorSubmit} ref={formRef} >

                <div className="popup-cont">
                  <Form.Group className="form-group" controlId="form-group">
                    <Form.Label>Rate</Form.Label>
                    <Rating
                      transition
                      allowFraction
                      showTooltip
                      onClick={handleRating}
                      onPointerEnter={onPointerEnter}
                      onPointerLeave={onPointerLeave}
                      onPointerMove={onPointerMove}
                      tooltipDefaultText='Rate'
                    /* Available Props */
                    />
                    {/* <TextInput type="number" name="rating" id="rate" required
                        placeholder="Enter the rate"
                        errorMessage="Please enter the rate"
                        onChange={handleChange}
                      /> */}
                  </Form.Group>
                  <Form.Group className="form-group" controlId="form-group">
                    <Form.Label>{strings.review}</Form.Label>
                    <TextInput name="review" id="review"
                      placeholder="Enter the review"
                      onChange={handleChange}
                    />
                    <div className='rating_image'>
                      <div className="rating_img_upload profile_img">
                        <img src={file ? file : NoImage} alt="Rating Image" />
                      </div>
                      <div className="upload_cls">
                        <div className="file_input">
                          <FileInput name="image" id="image"
                            fileType={["jpg", "png", "gif", "webp"]}
                            maxFileSize="5120 kb"
                            errorMessage={
                              {
                                required: strings.plz_upload_file,
                                fileType: "Only jpg,png,gif and webp files is allowed",
                                maxFileSize: strings.max_file_size
                              }
                            }
                            onChange={handleChangeImg}
                          />
                          <span for="image">{strings.upload_rating_image}</span>
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                  <div className="login-btn">
                    <Button type="submit" className="btn btn_primary">Submit</Button>
                  </div>
                  {/*<div className="already_cls">
                                <p>If you already have an account? <Link to="#" onClick={openLogin}>Sign In</Link></p>
                                                            </div>*/}
                </div>
              </ValidationForm>
            </div>
          </Modal.Body>

        </Modal></> : ""}
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(Order);