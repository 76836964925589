import React, { useRef, useEffect, useState } from "react";
import ListigPageComponent from "./Component/ListingPageComponent";
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
function ListingPageContainer(props) {
    const listInnerRef = useRef();
    const [currPage, setCurrPage] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [userList, setUserList] = useState([]);
    const [lastList, setLastList] = useState(false);

    const getProduct1 = async (page, value) => {
        let path = apiPath.product;
        var data = new FormData();
        data.append('page', currPage);
        let options = {
            method: 'POST',
            //				headers: { 'language': this.state.language },
            data: data
        }

        let result = await Http(path, options);
        if (result.status === 200) {

            /*if (!result.data.data.data.length) {
               setLastList(true);
               return;
            }998
            /*setPrevPage(currPage);
            setProduct({
               data: result.data.data.data,
            })*/
            //if (!lastList && prevPage !== currPage) {
            //getProduct();
            //}
            return result.data.data.data;
            //       if (page == 1) {
            //          setProduct({
            //             data: result.data.data.data,
            //          })
            //          setComman({ ...comman, last_page: result.data.data.last_page });
            //       }
            //       else {
            //          setProduct({
            //             data: product.data.concat(result.data.data.data)
            //          })
            //       }

            //       if (page === comman.last_page) {
            //          setComman({ ...comman, showLoad: 'hideCl' });
            //       }
            //    } else {
            //       toast.error(result.data.message);
            //    }
        } else {
            // toast.error(result.statusText);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            const response = await getProduct1(1)
            console.log(response, "<<<");
            if (!response.length) {
                setLastList(true);
                return;
            }
            setPrevPage(currPage);
            setUserList([...userList, ...response]);
        };
        if (!lastList && prevPage !== currPage) {
            fetchData();
        }
    }, [currPage, lastList, prevPage, userList]);

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            console.log(scrollTop, clientHeight, scrollHeight)
            if (scrollTop + clientHeight === scrollHeight) {
                console.log(currPage, "currPage")
                setCurrPage(currPage + 1);

            }
        }
    };

    return (
        <ListigPageComponent
            onScroll={onScroll}
            listInnerRef={listInnerRef}
            userList={userList}
        />
    );
}

export default ListingPageContainer;
