
import React, { Component } from 'react';
import Moment from 'react-moment';
const moment = require('moment');
class DayPipe extends Component {
	render() {
		if (this.props.date != '') {
			// alert(this.props.date, "")

			let today = new Date().toISOString()
			// alert(today, "today")
			const startDate = this.props.date;
			const endDate = today;

			const diffInMs = new Date(endDate) - new Date(startDate)
			const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
			let seconds = Math.floor((new Date(endDate) - new Date(startDate)) * 0.001);

			if (seconds < 10) {
				return (

					<p>just now</p>
				);

			} else if (seconds < 60) {
				return (

					<p>{seconds} seconds ago</p>
				);
				// return
			}
			else if (seconds < 3600) {
				let minutes = Math.floor(seconds / 60)
				if (minutes > 1)
					return (

						<p>{minutes} minutes ago</p>
					);
				// return minutes + " minutes ago";
				else
					return (

						<p>1 minute ago</p>
					);
				// return "1 minute ago";
			}
			// 1 day
			else if (seconds < 86400) {
				let hours = Math.floor(seconds / 3600)
				if (hours > 1)
					return hours + " hours ago";
				else
					return "1 hour ago";
			}
			//31 days
			else if (seconds < 2678400) {
				let days = Math.floor(seconds / 86400)
				if (days > 1)
					return days + " days ago";
				else
					return "1 day ago";
			}
			//months
			else if (seconds < 31536000) {
				let months = Math.floor(seconds / 2678400)
				if (months > 1)
					return months + " months ago";
				else
					return "1 month ago";
			}//year
			else {
				let years = Math.floor(seconds / 31536000)
				if (years > 1)
					return years + " years ago";
				else
					return "1 year ago";
			}

		} else {
			return ''
		}
	}
}

export default DayPipe;