import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import Accordion from 'react-bootstrap/Accordion';

import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

import { useParams } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import InfiniteScroll from 'react-infinite-scroll-component';

import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ProductList from './Component/ProductList';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import ImgBannerPrd from '../assets/img/banner-prd.png';
import ImgMBannerPrd from '../assets/img/m-banner-prd.png';
import ImgCross from '../assets/img/close.png';
import ImgSortIc from '../assets/img/sort_ic.png';
import ImgFilterIc from '../assets/img/filter_ic.png';

import noProduct from '../assets/img/noProduct.png';

import LocalizedStrings from "react-localization";
import localization from "../localization";
import Banner from './Component/Banner';
import Modal from 'react-bootstrap/Modal';
import Imgcross from '../assets/img/cross.png';
import { Button, Form } from 'react-bootstrap';
import { SelectGroup, ValidationForm } from "react-bootstrap4-form-validation";

let strings = new LocalizedStrings(localization);
// const categoryOptions = {
//    margin: 15,
//    nav: true,
//    dots: false,
//    loop: false,
//    rewind: true,
//    center: false,
//    responsive: {
//       0: {
//          items: 3,
//          margin: 7.5
//       },
//       577: {
//          items: 4
//       },
//       992: {
//          items: 9
//       },
//       1199: {
//          items: 10
//       },
//       1351: {
//          items: 13
//       }
//    }
// }
const SearchPage = (Props) => {
   const formRef = React.createRef();
   const [modalIsOpen, setModalIsOpen] = useState(false);

   const setModalIsOpenToTrue = (id, attributes, variantImage) => {
      setComman({ ...comman, product_id: id, attributes: attributes, product_variant_image: variantImage });
      // alert(id)
      setModalIsOpen(true)
   }

   const setModalIsOpenToFalse = () => {
      setModalIsOpen(false)
   }

   const showAddtoCart = async (e, id, attributes, variantImage) => {
      e.preventDefault();
      if (attributes.length > 0) {
          setModalIsOpenToTrue(id, attributes, variantImage);
      } else {
          setComman({ ...comman, product_id: id, attribute_value_id: null, product_variant_image: variantImage });
          addtoCart(id, null, variantImage);
      }
   };

   const addtoCart = async (id, type, variantImage) => {
      let path = apiPath.AddtoCart;
      var data = new FormData();
      data.append('product_id', id);
      data.append('qty', 1);
      if (type == null && comman.attribute_value_id != null) {
         data.append('product_attribute_values_id', comman.attribute_value_id);
      }
      else if (type == 'form') {
         const attributeValueId = document.querySelector('input[name="attribute_value_id"]:checked');
         if (attributeValueId != null && attributeValueId != "") {
            data.append('product_attribute_values_id', attributeValueId.value);
         }
      }

      if (variantImage) {
         data.append('variant_image_id', variantImage);
      }

      let options = {
         method: 'POST',
         data: data
      }

      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            toast.success(result.data.message);
            setComman({ ...comman, addCart: true });
         }
         else {
            toast.error(result.data.message);
         }
         setModalIsOpenToFalse();
      }
   }

   const params = useParams();
   const [product, setProduct] = useState([]);
   const [comman, setComman] = useStateWithCallbackLazy({
      page: 1,
      last_page: 0,
      is_Wishlist: 0,
      product_id: 0,
      attributes: [],
      attribute_value_id: null,
      product_variant_image: null,
      addCart: false
   });
   const [currPage, setCurrPage] = useState(1);
   const [brand, setBrand] = useState([])
   const [hasMore, setHasMore] = useState(true);
   const [currentLang, setCurrentLang] = useState(
      localStorage.getItem("current_lang")
         ? localStorage.getItem("current_lang")
         : "en"
   );
   strings.setLanguage(currentLang);
   const getProduct = async (page) => {
      console.log(params, "Value")
      let path = apiPath.advanceSearch;
      var data = new FormData();
      data.append('page', page);
      data.append("search", params.text ? params.text : '');
      let options = {
         method: 'POST',
         data: data
      }
      let result = await Http(path, options);
      if (result.status === 200) {
         if (result.data) {
            let resultJson = result.data.data;
            if (page == 1) {
               setBrand({
                  data: result.data.data.top_brand
               })
               setProduct({
                  data: result.data.data.current_product.data,
               })
               setComman({ ...comman, last_page: result.data.data.current_product.last_page, page: page });
            }
            else {
               setProduct({
                  data: product.data.concat(result.data.data.current_product.data)
               })
            }

            if (currPage === result.data.data.current_product.last_page) {
               setComman({ ...comman, page: page });
               setHasMore(false)
            }


         } else {
            toast.error(result.data.message);
         }
      } else {
         if (result.statusText != null) {
            toast.error(result.statusText);
         }
      }
   }
   const handleFavourite = async (e, id, type, index) => {

      let path = apiPath.AddRemoveWishlist;
      var data = new FormData()
      data.append('product_id', id);
      data.append('type', type);
      let options = {
         method: 'POST',
         data: data
      }

      let result = await Http(path, options);
      if (result.status === 200) {
         if (result.data.status) {
            var is_wish = product.data[index].is_wishlist;
            var is_wish_val = 0;

            if (type == 'add') {
               setProduct({ ...product, [product.data[index].is_wishlist]: 0 });
               setComman({ ...comman, is_Wishlist: 0 })
               // getProduct(1)
            }
            else {
               setProduct({ ...product, [product.data[index].is_wishlist]: 1 });
               // getProduct(1)
            }

            toast.success(result.data.message);
         } else {
            toast.error(result.data.message);
         }
      } else {
         if (result.statusText != null) {
            toast.error(result.statusText);
         }
      }

   }
   const onNext = () => {
      if (hasMore) {
         setCurrPage(prevPageNumber => prevPageNumber + 1)
      }
   }

   useEffect(() => {
      getProduct(currPage);

   }, [currPage, params]);

   useEffect(() => {
      getProduct(1);
   }, []);




   const addClass = (e, type) => {
      if (type == "short") {
         document.getElementsByTagName('body')[0].setAttribute("class", 'open_sort');
      }
      else {
         document.getElementsByTagName('body')[0].setAttribute("class", 'open_filter');
      }
   }




   return (
      <>
         <Header data={comman.addCart} searchStatus={false} />
         <main>
            {/* {brand.data ? brand.data.length > 0 ?
               <section className="space-cls category-sec"><div className="container">{brand.data ? <Banner data={brand.data} type="brand" options={categoryOptions} /> : null}</div></section> : null : null} */}
            <section className="space-cls product_listing">
               <div className="container">
                  <div className="product_wrap">
                     <div className="product_left_s">
                        <div className="filter_main">
                           <div className="filter_pop d-md-none">
                              <a className="short_icon" id="new" onClick={(e) => addClass(e, "short")} ><img src={ImgSortIc} /> Short</a>
                              <a className="filter_icon" id="new1" onClick={(e) => addClass(e, "filter")} ><img src={ImgFilterIc} /> Filter</a>
                           </div>
                        </div>
                     </div>
                     <div className="product_right_s">
                        <div className="row relative-loading" >
                           {product.data ? product.data.length > 0 ?
                              <InfiniteScroll
                                 // scrollableTarget="ProductList"
                                 dataLength={product.data.length}
                                 // scrollThreshold={0.80}
                                 next={onNext}
                                 hasMore={hasMore}
                                 loader={<div class="loader"></div>}
                              >
                                 <ProductList showAddtoCart={showAddtoCart} data={product.data} handleFavourite={handleFavourite} />
                              </InfiniteScroll>
                              :
                              <section className="space-cls no_image review_noImg">
                                 <div className="container">
                                    <div className="cart_row">
                                       <div className='col-md-12'>
                                          <h3>
                                             <center>
                                                <img src={noProduct} alt="Cart Product" />
                                             </center>
                                          </h3>
                                       </div>
                                    </div>
                                 </div>
                              </section> : ''}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </main>
         <Footer />
         {modalIsOpen ? <>
            <Modal show={true} animation={true} size="lg" backdrop="static" keyboard={false} centered >
               <Modal.Header>
                  <h5 className="modal-title" id="exampleModalLabe111l">{strings.attributes}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                     <img src={Imgcross} onClick={setModalIsOpenToFalse} />
                  </button>
               </Modal.Header>
               <Modal.Body className='modal-body'>
                  <div className="continue-btn review_modal">
                     <ValidationForm ref={formRef} >
                        <div className="popup-cont">
                           <Form.Group className="form-group" controlId="form-group">
                              <div className="prd-size">
                                 <div className="prd_size_label">
                                    {comman.attributes[0].attribute_values && comman.attributes[0].attribute_values.map((option, i) => (
                                       <label className="custom_radio_b" key={option.id}>
                                          <input
                                             type="radio"
                                             name="attribute_value_id"
                                             value={option.id}
                                             defaultChecked={i === 0}
                                          />
                                          <span className="checkmark">{option.value}</span>
                                       </label>
                                    ))}
                                 </div>
                              </div>
                           </Form.Group>
                           <div className="login-btn">
                              <Button onClick={(e) => addtoCart(comman.product_id, 'form', comman.product_variant_image)} type="button" className="btn btn_primary">{strings.add_to_bag}</Button>
                           </div>
                        </div>
                     </ValidationForm>
                  </div>
               </Modal.Body>
            </Modal></> : ""}
      </>
   )
}

export default SearchPage;