import React, { useState, useEffect, useRef, useContext } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import Accordion from 'react-bootstrap/Accordion';

import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

import { useParams } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import InfiniteScroll from 'react-infinite-scroll-component';

import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ProductList from '../view/Component/ProductList';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation } from 'react-router-dom';

import ImgBannerPrd from '../assets/img/banner-prd.png';
import ImgMBannerPrd from '../assets/img/m-banner-prd.png';
import ImgCross from '../assets/img/close.png';
import ImgSortIc from '../assets/img/sort_ic.png';
import ImgFilterIc from '../assets/img/filter_ic.png';

import noProduct from '../assets/img/noProduct.png';

import LocalizedStrings from "react-localization";
import localization from "../localization";
import Modal from 'react-bootstrap/Modal';
import Imgcross from '../assets/img/cross.png';
import { Button, Form } from 'react-bootstrap';
import { SelectGroup, ValidationForm } from "react-bootstrap4-form-validation";

import { Helmet } from 'react-helmet';
import LoadingOverlay from 'react-loading-overlay-ts';
import ScrollContext from '../ScrollContext';

let strings = new LocalizedStrings(localization);
const options = {
    rtl: true,
    margin: 0,
    nav: true,
    dots: false,
    loop: true,
    responsive: {
        0: {
            items: 1,
            margin: 7.5
        },
        420: {
            items: 1
        },
        577: {
            items: 1
        },
        992: {
            items: 1
        }
    }
};

const brandOption = {
    margin: 15,
    nav: true,
    dots: false,
    loop: true,
    responsive: {
        0: {
            items: 1.5,
            margin: 7.5
        },
        577: {
            items: 3
        },
        992: {
            items: 4
        },
        1366: {
            items: 5
        }
    }
}

const flowersOption = {
    margin: 15,
    nav: true,
    dots: false,
    loop: true,
    responsive: {
        0: {
            items: 2.3,
            margin: 7.5
        },
        577: {
            items: 3
        },
        992: {
            items: 5
        },
        1366: {
            items: 6
        }
    }
}

const comboOption = {
    margin: 15,
    nav: true,
    dots: false,
    loop: true,
    responsive: {
        0: {
            items: 1,
            margin: 7.5
        },
        420: {
            items: 2
        },
        577: {
            items: 2
        },
        992: {
            items: 2
        },
        1366: {
            items: 3
        }
    }
}

const DiscountOption = {
    margin: 15,
    nav: true,
    dots: false,
    loop: true,
    responsive: {
        0: {
            items: 1,
            margin: 7.5
        },
        420: {
            items: 2
        },
        577: {
            items: 2
        },
        992: {
            items: 3
        },
        1366: {
            items: 4
        }
    }
}


const Product = (Props) => {

    // Sticky sidebar
    const [isSticky, setIsSticky] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 992);
    const headerHeight = 110;
    const handleScroll = () => {
        if (isDesktop) {
            const scrollTop = window.scrollY;
            if (scrollTop > headerHeight) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        }
    };

    const handleResize = () => {
        setIsDesktop(window.innerWidth > 992);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, [isDesktop]);
    // End

    const { isScrolled } = useContext(ScrollContext);
    const location = useLocation();
    const formRef = React.createRef();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isActive, setActive] = useState(true)

    const setModalIsOpenToTrue = (id, attributes, variantImage) => {
        setComman({ ...comman, product_id: id, attributes: attributes, product_variant_image: variantImage });
        // alert(id)
        setModalIsOpen(true)
    }

    const setModalIsOpenToFalse = () => {
        setModalIsOpen(false)
    }

    const showAddtoCart = async (e, id, attributes, variantImage) => {
        e.preventDefault();
        if (attributes.length > 0) {
            setModalIsOpenToTrue(id, attributes, variantImage);
        } else {
            setComman({ ...comman, product_id: id, attribute_value_id: null, product_variant_image: variantImage });
            addtoCart(id, null, variantImage);
        }
    };

    const addtoCart = async (id, type, variantImage) => {
        let path = apiPath.AddtoCart;
        var data = new FormData();
        data.append('product_id', id);
        data.append('qty', 1);
        if (type == null && comman.attribute_value_id != null) {
            data.append('product_attribute_values_id', comman.attribute_value_id);
        }
        else if (type == 'form') {
            const attributeValueId = document.querySelector('input[name="attribute_value_id"]:checked');
            if (attributeValueId != null && attributeValueId != "") {
                data.append('product_attribute_values_id', attributeValueId.value);
            }
        }

        if (variantImage) {
            data.append('variant_image_id', variantImage);
        }

        let options = {
            method: 'POST',
            data: data
        }

        let result = await Http(path, options);
        if (result.status == 200) {
            if (result.data.status) {
                toast.success(result.data.message);
            }
            else {
                toast.error(result.data.message);
            }
            setModalIsOpenToFalse();
        }
    }

    const listInnerRef = useRef();
    const [lastList, setLastList] = useState(false);
    const params = useParams();
    const [open, setOpen] = React.useState(false);
    const [filters, setFilters] = useState([]);
    const [product, setProduct] = useState([]);
    const [value, setValue] = React.useState([2, 10]);
    const [meta, setMeta] = useStateWithCallbackLazy({
        'meta_title': 'Best Perfume in Kuwait | Men, Women, Unisex & Bakhoor | TPS',
        'meta_description': 'Best perfume for men, women, and unisex, including bakhoor and beauty products. Available for delivery in Kuwait from The Perfume Smell',
        'meta_keywords': 'The perfume smell'
    });
    const [comman, setComman] = useStateWithCallbackLazy({
        page: 1,
        last_page: 0,
        showLoad: 'showCl',
        minPrice: 0,
        maxPrice: 0,
        short: 'A_TO_Z',
        category: [],
        brand: [],
        rating: 0,
        notes: [],
        perfumeTypes: [],
        is_Wishlist: 0,
        product_id: 0,
        attributes: [],
        attribute_value_id: null,
        product_variant_image: null,
    });
    const [currPage, setCurrPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [prevPage, setPrevPage] = useState(0);
    const [userList, setUserList] = useState([]);
    // const [lastList, setLastList] = useState(false);


    const handleOpen = () => {
        setOpen(!open);
    };

    const [currentLang, setCurrentLang] = useState(
        localStorage.getItem("current_lang")
            ? localStorage.getItem("current_lang")
            : "en"
    );
    strings.setLanguage(currentLang);



    const getProduct = async (page) => {
        setActive(true);
        let path = apiPath.product;
        var data = new FormData();
        
        const queryParams = new URLSearchParams(location.search);
        const searchValue = queryParams.get('search');
        if (searchValue) {
            data.append('name', searchValue);
        }

        data.append('page', page);
        data.append('type', params.type);
        data.append('typeId', params.typeId);
        if (comman.brand.length > 0) {
            data.append('brand_id', comman.brand);
        }

        if (params.typeId == undefined && params.type != '' && params.type != undefined && comman.category.length <= 0 && comman.brand.length <= 0 && comman.rating <= 0 && comman.maxPrice <= 0 && comman.notes.length <= 0 && comman.perfumeTypes <= 0) {
            data.append('category_slug', params.type);
            data.set('type', 'category');
            data.set('typeId', params.type);
        }

        if (params.type == 'brand' && params.typeId != '' && comman.category.length <= 0 && comman.brand.length <= 0 && comman.rating <= 0 && comman.maxPrice <= 0 && comman.notes.length <= 0 && comman.perfumeTypes <= 0) {
            data.append('brand_id', params.typeId);
        }
        if (params.type == 'category' && params.typeId != '' && comman.category.length <= 0 && comman.brand.length <= 0 && comman.rating <= 0 && comman.maxPrice <= 0 && comman.notes.length <= 0 && comman.perfumeTypes <= 0) {
            data.append('category_id', params.typeId);
        }
        if (params.type == 'sub' && params.typeId != '' && comman.category.length <= 0 && comman.brand.length <= 0 && comman.rating <= 0 && comman.maxPrice <= 0 && comman.notes.length <= 0 && comman.perfumeTypes <= 0) {
            data.append('sub_category_id', params.typeId);
        }

        if (comman.category.length > 0) {

            data.append('category_id', comman.category);
        }

        if (comman.perfumeTypes.length > 0) {

            data.append('perfumeTypes', comman.perfumeTypes);
        }
        if (comman.notes.length > 0) {
            data.append('notes_id', comman.notes);
        }
        if (comman.rating > 0) {
            data.append('rating', comman.rating);
        }
        if (params.type == "price") {
            data.append('price', params.typeId);
        }
        if (params.type == "discount") {
            data.append('discount', params.typeId);
        }
        if (value > 0) {
            data.append('price', value);
        }
        if (comman.short) {
            data.append('order_by', comman.short);
        }
        else {
        }


        let options = {
            method: 'POST',
            //				headers: { 'language': this.state.language },
            data: data
        }

        let result = await Http(path, options);
        if (result.status === 200) {
            if (result.data) {
                let resultJson = result.data.result;

                if (page == 1) {
                    setProduct({
                        data: result.data.data.data,
                    })
                    setComman({ ...comman, last_page: result.data.data.last_page, page: page });
                }
                else {
                    setProduct({
                        data: product.data.concat(result.data.data.data)
                    })
                }

                if (result.data.data.meta_title != "") {
                    setMeta({
                        'meta_title': result.data.data.meta_title,
                        'meta_description': result.data.data.meta_description,
                        'meta_keywords': result.data.data.meta_keywords
                    });
                }

                if (currPage === result.data.data.last_page) {
                    setComman({ ...comman, showLoad: 'hideCl', page: page });
                    setHasMore(false)
                }


            } else {
                toast.error(result.data.message);
            }
        } else {
            if (result.statusText != null) {
                toast.error(result.statusText);
            }
        }
        setActive(false);
    }

    const getFilterData = async () => {
        let path = apiPath.getFilterData;
        let options = {
            method: 'POST',
            //				headers: { 'language': this.state.language },
        }

        let result = await Http(path, options);

        if (result.status === 200) {
            if (result.data.status) {
                setFilters({
                    data: result.data.data,
                })
                // alert(filter.data.category)
            } else {
                toast.error(result.data.message);
            }
        } else {
            if (result.statusText != null) {
                toast.error(result.statusText);
            }
        }
    }

    const handleOnFilter = async (e, type, value) => {

        // alert(e.target.name)
        // alert(e.target.value)
        if (type === 'short') {
            // await setComman({ sort: e.target.value })
            // await getProduct(1, comman)
            await setComman({ ...comman, short: e.target.value })
        }
        if (type === 'category') {
            if (e.target.checked === true) {
                comman.category.push(value);
            }
            else {
                const array = comman.category
                var index = array.indexOf(value)
                if (index !== -1) {
                    array.splice(index, 1);
                    setComman({ ...comman, category: array });
                }
            }
            setComman({ ...comman, category: [...new Set(comman.category)] });


        }
        if (type === 'perfumeTypes') {
            if (e.target.checked === true) {
                comman.perfumeTypes.push(value);
            }
            else {
                const array = comman.perfumeTypes
                var index = array.indexOf(value)
                if (index !== -1) {
                    array.splice(index, 1);
                    setComman({ ...comman, perfumeTypes: array });
                }
            }
            setComman({ ...comman, perfumeTypes: [...new Set(comman.perfumeTypes)] });


        }

        if (type === 'brand') {
            if (e.target.checked === true) {
                comman.brand.push(value);
            }
            else {
                const array = comman.brand
                var index = array.indexOf(value)
                if (index !== -1) {
                    array.splice(index, 1);
                    setComman({ ...comman, brand: array });
                }
                // comman.brand.pop(value);
            }
            setComman({ ...comman, brand: [...new Set(comman.brand)] });
            getProduct(1, comman)
        }
        if (type === 'rating') {
            setComman({ ...comman, rating: value });
            // getProduct(1, comman)
            // await setComman({ ...comman, rating: value });
        }
        if (type === 'notes') {
            if (e.target.checked === true) {
                comman.notes.push(value);
            }
            else {
                comman.notes.pop(value);
            }
            setComman({ ...comman, notes: [...new Set(comman.notes)] });
            // getProduct(1, comman)

        }
        setCurrPage(prevPageNumber => 1)

    }

    const handleFavourite = async (e, id, type, index) => {

        let path = apiPath.AddRemoveWishlist;
        var data = new FormData()
        data.append('product_id', id);
        data.append('type', type);
        let options = {
            method: 'POST',
            data: data
        }

        let result = await Http(path, options);
        if (result.status === 200) {
            if (result.data.status) {
                var is_wish = product.data[index].is_wishlist;
                var is_wish_val = 0;

                if (type == 'add') {
                    setProduct({ ...product, [product.data[index].is_wishlist]: 0 });
                    setComman({ ...comman, is_Wishlist: 0 })
                    // getProduct(1)
                }
                else {
                    setProduct({ ...product, [product.data[index].is_wishlist]: 1 });
                    // getProduct(1)
                }

                toast.success(result.data.message);
            } else {
                toast.error(result.data.message);
            }
        } else {
            if (result.statusText != null) {
                toast.error(result.statusText);
            }
        }


    }

    const onScroll = async (e) => {
        // console.log((window.pageYOffset + document.documentElement.clientHeight).toFixed(0), document.documentElement.scrollHeight);
        console.log(comman.showLoad, "comman.showLoad")
        if (parseInt((window.pageYOffset + document.documentElement.clientHeight).toFixed(0)) === (document.documentElement.scrollHeight)) {
            if (comman.showLoad != "hideCl") {
                setCurrPage(prevPageNumber => prevPageNumber + 1)
            }


        }
    };

    const onNext = () => {
        if (hasMore) {
            setCurrPage(prevPageNumber => prevPageNumber + 1)
        }
    }

    useEffect((e) => {
        // window.scrollTo(0, 0)
        getFilterData();
        // window.addEventListener('scroll', onScroll);
        // return () => window.removeEventListener("scroll", onScroll)
    }, [])

    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        // localStorage.setItem("type", params.type)
        getProduct(currPage);

    }, [currPage, params, comman.category, comman.rating, comman.notes, comman.ac, comman.perfumeTypes]);


    useEffect(() => {
        localStorage.setItem("type", params.type)
        getProduct(1);
    }, [value, comman.short]);

    const loadMore = (data) => {
        var page = comman.page
        page = page + 1;
        setComman({ ...comman, page: page });
        getProduct(page);
    }

    const priceChange = (newValue) => {
        // alert(value)
        // alert(e.target.value)
        // setComman({ ...comman, maxPrice: value });
        setValue(newValue);
        // getProduct(1);


        // setComman({ ...comman, maxPrice: value });
    }



    const addClass = (e, type) => {
        if (type == "short") {
            document.getElementsByTagName('body')[0].setAttribute("class", 'open_sort');
        }
        else {
            document.getElementsByTagName('body')[0].setAttribute("class", 'open_filter');
        }
    }


    const removeClass = (f, type) => {
        if (type == "short") {
            document.getElementsByTagName('body')[0].setAttribute("class", '');
        }
        else {
            document.getElementsByTagName('body')[0].setAttribute("class", '');
        }

        // alert(type)


    }

    return (
        <>
        <LoadingOverlay active={isActive} spinner>
            <Helmet>
                <title>{meta.meta_title != "" ? meta.meta_title : "Best Perfume in Kuwait | Men, Women, Unisex & Bakhoor | TPS"}</title>
                <meta name="description" content={meta.meta_description != "" ? meta.meta_description : "Best perfume for men, women, and unisex, including bakhoor and beauty products. Available for delivery in Kuwait from The Perfume Smell"} />
                <meta name="keywords" content={meta && meta.meta_keywords != "" ? meta.meta_keywords : "The perfumesmell"} />
            </Helmet>
            <Header new={params.type} data={true} isScrolled={isScrolled} />
            <main>
                {filters.data ?
                    <section className="space-cls banner-sec">
                        <div className="container">
                            {filters.data.limited_product.length > 0 &&
                                <OwlCarousel className="owl-carousel  owl-theme" {...options}  >
                                    {filters.data.limited_product.map((option, index) =>
                                        <div className="item">
                                            <div className="banner-img">
                                                <a href={'/product/' + (option.type != "product" ? option.type : "product-detail") + "/" + option.banner_for_id}>
                                                    <picture>
                                                        <source media="(min-width:575.98px)" srcSet={option.file} />
                                                        <img src={option.file} alt="" />
                                                    </picture>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {/* {filters.data.limited_product.map((option, index) => { */}
                                    {/* <div className="item">
                              <div className="banner-img">
                                 <picture>
                                    <source media="(min-width:575.98px)" srcSet={option.file} />
                                    <img src={option.file} alt="" />
                                 </picture>
                              </div>
                           </div> */}

                                    {/* <div className="item">
                        <div className="banner-img">
                           <picture>
                              <source media="(min-width:575.98px)" srcSet={ImgBannerPrd} />
                              <img src={ImgMBannerPrd} alt="" />
                           </picture>
                        </div>
                     </div>
                     <div className="item">
                        <div className="banner-img">
                           <picture>
                              <source media="(min-width:575.98px)" srcSet={ImgBannerPrd} />
                              <img src={ImgMBannerPrd} alt="" />
                           </picture>
                        </div>
                     </div> */}
                                    {/* })} */}
                                </OwlCarousel>
                            }

                        </div>
                    </section> : null}
                <section className="space-cls product_listing">
                    <div className="container">
                        <div className="product_wrap">
                            <div className={`product_left_s ${isScrolled ? 'is_scrolled' : ''}`}>
                                <div className="filter_main">
                                    <div className="filter_pop d-md-none">
                                        <a className="short_icon" id="new" onClick={(e) => addClass(e, "short")} ><img src={ImgSortIc} /> Short</a>
                                        <a className="filter_icon" id="new1" onClick={(e) => addClass(e, "filter")} ><img src={ImgFilterIc} /> Filter</a>
                                    </div>
                                </div>
                                <div className="side-filter">

                                    <Accordion defaultActiveKey="0" className="sort_by">
                                        <button type="button" className="btn_cross d-md-none" onClick={(f) => removeClass(f, "short")}>
                                            <img src={ImgCross} alt="Close" />
                                        </button>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>{strings.short_by}</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="filter_cont_s">
                                                    <label className="custom_radio_b">
                                                        <input type="radio" name="short" value="A_TO_Z" checked={comman.short === 'A_TO_Z'} onChange={(e) => handleOnFilter(e, 'short', 'A_TO_Z')} />
                                                        <span className="checkmark"></span>A To Z
                                                    </label>
                                                    <label className="custom_radio_b">
                                                        <input name="short" type="radio" value="Z_TO_A" checked={comman.short === 'Z_TO_A'} onChange={(e) => handleOnFilter(e, 'short', 'Z_TO_A')} />
                                                        <span className="checkmark"></span>Z To A
                                                    </label>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    <Accordion defaultActiveKey="1" className="filter_info">
                                        <div className="filter_mob_open">
                                            <button type="button" className="btn_cross d-md-none" onClick={(f) => removeClass(f, "filter")}>
                                                <img src={ImgCross} alt="Close" />
                                            </button>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>{strings.category}</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="filter_cont_s">


                                                        {filters.data && filters.data.category.map((option, index) => {

                                                            return (<label className="custom_checkbox">
                                                                <input type="checkbox" value={option.id} onClick={(e) => handleOnFilter(e, 'category', option.id)} />
                                                                <span className="checkmark"></span>
                                                                {option.name}
                                                                <span className="filter_meta">{option.total_product}</span>
                                                            </label>)
                                                        })}


                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>{strings.brands}</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="filter_cont_s">
                                                        {filters.data && filters.data.brand.map((option, index) => {
                                                            return (<label className="custom_checkbox">
                                                                <input type="checkbox" value={option.id} onClick={(e) => handleOnFilter(e, 'brand', option.id)} />
                                                                <span className="checkmark"></span>
                                                                {option.name}
                                                                <span className="filter_meta">{option.total_product}</span>
                                                            </label>)
                                                        })}


                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>{strings.rating}</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="filter_cont_s">
                                                        <label className="custom_radio_b">
                                                            <input type="radio" name="rating" value="5" checked={comman.rating === 5 ? true : false} onClick={(e) => handleOnFilter(e, 'rating', 5)} />
                                                            <span className="checkmark"></span>{strings.start45}
                                                        </label>

                                                        <label className="custom_radio_b">
                                                            <input type="radio" name="rating" value="4" checked={comman.rating === 4 ? true : false} onClick={(e) => handleOnFilter(e, 'rating', 4)} />
                                                            <span className="checkmark"></span>{strings.start34}
                                                        </label>

                                                        <label className="custom_radio_b">
                                                            <input type="radio" name="rating" value="3" checked={comman.rating === 3 ? true : false} onClick={(e) => handleOnFilter(e, 'rating', 3)} />
                                                            <span className="checkmark"></span>{strings.start23}
                                                        </label>

                                                        <label className="custom_radio_b">
                                                            <input type="radio" name="rating" value="2" checked={comman.rating === 2 ? true : false} onClick={(e) => handleOnFilter(e, 'rating', 2)} />
                                                            <span className="checkmark"></span>{strings.start12}
                                                        </label>

                                                        <label className="custom_radio_b">
                                                            <input type="radio" name="rating" value="1" checked={comman.rating === 1 ? true : false} onClick={(e) => handleOnFilter(e, 'rating', 1)} />
                                                            <span className="checkmark"></span>{strings.start01}
                                                        </label>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="6">
                                                <Accordion.Header>{strings.price}</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="filter_cont_s">


                                                        <Slider
                                                            value={value}
                                                            onChange={priceChange}
                                                            valueLabelDisplay="auto"
                                                            min={0}
                                                            max={filters.data ? filters.data.max_price : 0}
                                                        // value={comman.maxPrice}
                                                        // onChange={priceChange}
                                                        />

                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            {/* <Accordion.Item eventKey="4">
                                    <Accordion.Header>{strings.notes}</Accordion.Header>
                                    <Accordion.Body>
                                       <div className="filter_cont_s">


                                          {filters.data && filters.data.note_image.map((option, index) => {

                                             return (<label className="custom_checkbox">
                                                <input type="checkbox" value={option.id} onClick={(e) => handleOnFilter(e, 'notes', option.id)} />
                                                <span className="checkmark"></span>
                                                {option.name}
                                             </label>)
                                          })}


                                       </div>
                                    </Accordion.Body>
                                 </Accordion.Item> */}
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>{strings.perfume_accords}</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="filter_cont_s">


                                                        {filters.data && filters.data.notes.map((option, index) => {



                                                            return (<label className="custom_checkbox  custom_checkbox_accords" style={{ 'background': option.color }}>
                                                                <input type="checkbox" value={option.id} onClick={(e) => handleOnFilter(e, 'notes', option.id)} />
                                                                <span className="checkmark checkmark_accords" ></span>
                                                                {option.name}
                                                            </label>)
                                                        })}


                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="6">
                                                <Accordion.Header>{strings.perfumeType}</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="filter_cont_s">
                                                        {filters.data && filters.data.perfumeType.map((option, index) => {
                                                            return (<label className="custom_checkbox  custom_checkbox_accords" style={{ 'background': option.color }}>
                                                                <input type="checkbox" value={option.id} onClick={(e) => handleOnFilter(e, 'perfumeTypes', option.id)} />
                                                                <span className="checkmark checkmark_accords" ></span>
                                                                {option.name}
                                                            </label>)
                                                        })}


                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </div>
                                    </Accordion>


                                    <div className="" id="accordionExample1">
                                        <div>
                                            {/* <select className='accordion-button'>

                                    <option>Please choose one option</option>
                                    {filters.data && filters.data.category.map((option, index) => {
                                       return <option key={option.id} >
                                          {option.name}
                                       </option>
                                    })}
                                 </select>
                                 <button type="button" className="btn_cross d-md-none">
                                <img src={{ImgClose}} alt="Close"/>    
                              </button>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingTwo">
                                     <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                     Category
                                     </button>
                                  </h2>
                                  <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="accordion" id="accordionExample2">
                                           <div className="accordion-item">
                                              <h2 className="accordion-header" id="mensFragrance">
                                                 <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInnerOne" aria-expanded="true" aria-controls="collapseInnerOne">
                                                 Mens Fragrance
                                                 </button>
                                              </h2>
                                              <div id="collapseInnerOne" className="accordion-collapse collapse show" aria-labelledby="mensFragrance" data-bs-parent="#accordionExample2">
                                                 <div className="accordion-body">
                                                    <div className="filter_cont_s">
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Perfumes
                                                       <span className="filter_meta">1636</span>
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Deodorants/Roll-Ons
                                                       <span className="filter_meta">1636</span>
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Body Mist/Spray
                                                       <span className="filter_meta">1636</span>
                                                       </label>
                                                    </div>
                                                 </div>
                                              </div>
                                           </div>
                                           <div className="accordion-item">
                                              <h2 className="accordion-header" id="WomensFragrance">
                                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInnerTwo" aria-expanded="true" aria-controls="collapseInnerTwo">
                                                 Womens Fragrance
                                                 </button>
                                              </h2>
                                              <div id="collapseInnerTwo" className="accordion-collapse collapse" aria-labelledby="WomensFragrance" data-bs-parent="#accordionExample2">
                                                 <div className="accordion-body">
                                                    <div className="filter_cont_s">
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       24/7 electricity
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Inverter
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Luxury
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Party Homes
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Super Host
                                                       </label>
                                                    </div>
                                                 </div>
                                              </div>
                                           </div>
                                           <div className="accordion-item">
                                              <h2 className="accordion-header" id="HomeFragrance">
                                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInnerThree" aria-expanded="true" aria-controls="collapseInnerThree">
                                                 Home Fragrance
                                                 </button>
                                              </h2>
                                              <div id="collapseInnerThree" className="accordion-collapse collapse" aria-labelledby="HomeFragrance" data-bs-parent="#accordionExample2">
                                                 <div className="accordion-body">
                                                    <div className="filter_cont_s">
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       24/7 electricity
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Inverter
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Luxury
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Party Homes
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Super Host
                                                       </label>
                                                    </div>
                                                 </div>
                                              </div>
                                           </div>
                                           <div className="accordion-item">
                                              <h2 className="accordion-header" id="VintageFragrance">
                                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInnerFour" aria-expanded="true" aria-controls="collapseInnerFour">
                                                 Vintage Collection
                                                 </button>
                                              </h2>
                                              <div id="collapseInnerFour" className="accordion-collapse collapse" aria-labelledby="VintageFragrance" data-bs-parent="#accordionExample2">
                                                 <div className="accordion-body">
                                                    <div className="filter_cont_s">
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       24/7 electricity
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Inverter
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Luxury
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Party Homes
                                                       </label>
                                                       <label className="custom_checkbox">
                                                       <input type="checkbox" />
                                                       <span className="checkmark"></span>
                                                       Super Host
                                                       </label>
                                                    </div>
                                                 </div>
                                              </div>
                                           </div>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingThree">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                     Brands
                                     </button>
                                  </h2>
                                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           Gucci
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           Ck
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           Puma
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFour">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                     Price
                                     </button>
                                  </h2>
                                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 0 - KWD 50
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" checked=""/>
                                           <span className="checkmark"></span>
                                           KWD 51 - KWD 100
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 101 - KWD 150
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 151 - KWD 200
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFive">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                     Discount
                                     </button>
                                  </h2>
                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 0 - KWD 50
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" checked=""/>
                                           <span className="checkmark"></span>
                                           KWD 51 - KWD 100
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 101 - KWD 150
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 151 - KWD 200
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header " id="headingSix">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                     Rating
                                     </button>
                                  </h2>
                                  <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_radio_b">
                                           <input type="radio" checked="checked" name="radio"/>
                                           <span className="checkmark"></span>All
                                           </label>
                                           <label className="custom_radio_b">
                                           <input type="radio" name="radio"/>
                                           <span className="checkmark"></span>4 Stars & Above 601
                                           </label>
                                           <label className="custom_radio_b">
                                           <input type="radio" name="radio"/>
                                           <span className="checkmark"></span>3 Stars & Above 642
                                           </label>
                                           <label className="custom_radio_b">
                                           <input type="radio" name="radio"/>
                                           <span className="checkmark"></span>2 Stars & Above 643
                                           </label>
                                           <label className="custom_radio_b">
                                           <input type="radio" name="radio"/>
                                           <span className="checkmark"></span>1 Star & Above 643 
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFive">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                     Size
                                     </button>
                                  </h2>
                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 0 - KWD 50
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" checked=""/>
                                           <span className="checkmark"></span>
                                           KWD 51 - KWD 100
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 101 - KWD 150
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 151 - KWD 200
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFive">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                     Concentration Type
                                     </button>
                                  </h2>
                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 0 - KWD 50
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" checked=""/>
                                           <span className="checkmark"></span>
                                           KWD 51 - KWD 100
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 101 - KWD 150
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 151 - KWD 200
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFive">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                     Notes
                                     </button>
                                  </h2>
                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 0 - KWD 50
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" checked=""/>
                                           <span className="checkmark"></span>
                                           KWD 51 - KWD 100
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 101 - KWD 150
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 151 - KWD 200
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFive">
                                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                     Perfume Accords
                                     </button>
                                  </h2>
                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample1">
                                     <div className="accordion-body">
                                        <div className="filter_cont_s">
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 0 - KWD 50
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" checked=""/>
                                           <span className="checkmark"></span>
                                           KWD 51 - KWD 100
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                           KWD 101 - KWD 150
                                           </label>
                                           <label className="custom_checkbox">
                                           <input type="checkbox" />
                                           <span className="checkmark"></span>
                                             KWD 151 - KWD 200
                                           </label>
                                        </div>
                                     </div>
                                  </div>
                               </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`product_right_s ${isScrolled ? 'is_scrolled' : ''}`}>
                                <div className="row relative-loading" >
                                    {product.data ? product.data.length > 0 ?
                                        <InfiniteScroll
                                            // scrollableTarget="ProductList"
                                            dataLength={product.data.length}
                                            // scrollThreshold={0.80}
                                            next={onNext}
                                            hasMore={hasMore}
                                            loader={<div class="loader"></div>}
                                        >
                                            <ProductList showAddtoCart={showAddtoCart} data={product.data} handleFavourite={handleFavourite} />
                                            {/* </InfiniteScroll> */}

                                            {/* <ProductList data={product.data} handleFavourite={handleFavourite} /> */}
                                        </InfiniteScroll>
                                        :
                                        <section className="space-cls no_image review_noImg">
                                            <div className="container">
                                                <div className="cart_row">
                                                    <div className='col-md-12'>
                                                        <h3>
                                                            <center>
                                                                <img src={noProduct} alt="Cart Product" />
                                                            </center>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </section> : ''}
                                </div>

                                {/* <div className={"load-more d-flex justify-content-center mt-2 " + comman.showLoad}>

                           <button type="button" className="btn btn_primary" onClick={loadMore}>Load More</button>
                        </div> */}

                                {/*<div className="sale-sec">
                     <OwlCarousel className="owl-carousel  owl-theme" {...flowersOption}  > 
                
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale1} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale2} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale3} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale4} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale1} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale2} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale3} alt=""/>
                              </a>
                            </div>
                          </div>
                          <div className="item">
                            <div className="sale-prd">
                              <a href="#">
                                <img src={ImgmSale4} alt=""/>
                              </a>
                            </div>
                          </div>
                        
                        </OwlCarousel>
    </div>*/}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </LoadingOverlay>
            {modalIsOpen ? <>
                <Modal show={true} animation={true} size="lg" backdrop="static" keyboard={false} centered >
                    <Modal.Header>
                        <h5 className="modal-title" id="exampleModalLabe111l">{strings.attributes}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src={Imgcross} onClick={setModalIsOpenToFalse} />
                        </button>
                    </Modal.Header>
                    <Modal.Body className='modal-body'>
                        <div className="continue-btn review_modal">
                            <ValidationForm ref={formRef} >
                                <div className="popup-cont">
                                    <Form.Group className="form-group" controlId="form-group">
                                        <div className="prd-size">
                                            <div className="prd_size_label">
                                                {comman.attributes[0].attribute_values && comman.attributes[0].attribute_values.map((option, i) => (
                                                    
                                                        <label className="custom_radio_b" key={option.id}>
                                                                <input
                                                                    type="radio"
                                                                    name="attribute_value_id"
                                                                    value={option.id}
                                                                    defaultChecked={i === 0}
                                                                />
                                                                <span className="checkmark">{option.value}</span>
                                                        </label>
                                                ))}
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <div className="login-btn">
                                        <Button onClick={(e) => addtoCart(comman.product_id, 'form', comman.product_variant_image)} type="button" className="btn btn_primary">{strings.selectOption}</Button>
                                    </div>
                                </div>
                            </ValidationForm>
                        </div>
                    </Modal.Body>
                </Modal></> : ""}
        </>
    )
}

export default Product;