import React, { useState, useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';

import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ImgPhone from '../assets/img/phone.png';
import ImgEmail from '../assets/img/email.png';
import ImgLocation from '../assets/img/location.png';
import DrawMapContact from '../view/DrawMapContact';
import ContactForm from '../view/Component/ContactForm';

import LocalizedStrings from 'react-localization';
import localization from '../localization';
let strings = new LocalizedStrings(localization);



//29.338938,48.069438,16z
const Contact = () => {
    const places = {
        address: 'The Perfume Smell',
        lat: 29.338938,
        lng: 48.069438,
    }

    const [currentLang, setCurrentLang] = useState(localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') : 'en');

    strings.setLanguage(currentLang);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Header />
            <main>
                <section className="space-cls cart-page">
                    <div className="container">
                        <div className="">
                            <h2 className="heading_t1">{strings.contactUs}</h2>
                            <Row>
                                <Col lg="6">
                                    <ContactForm />
                                </Col>
                                <Col lg="6">
                                    <div className='contact-map'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6956.1404252093325!2d48.069438!3d29.338938!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfbfcb2369dc62796!2sThe%20Perfume%20Smell!5e0!3m2!1sen!2skw!4v1674537833198!5m2!1sen!2skw" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <Row>
                            <Col md="4">
                                <div className='contact_sec_info_itm'>
                                    <div className="icon">
                                        <Image src={ImgPhone} />
                                    </div>
                                    <div className="term-cont">
                                        <h4>{strings.phoneNumber}</h4>
                                        <p><a href="tel:+96566027063">+965 66027063</a></p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className='contact_sec_info_itm'>
                                    <div className="icon">
                                        <Image src={ImgEmail} />
                                    </div>
                                    <div className="term-cont">
                                        <h4>{strings.emailAddress}</h4>
                                        <p><a href="mailto:info@theperfumesmell.com">info@theperfumesmell.com</a></p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className='contact_sec_info_itm'>
                                    <div className="icon">
                                        <Image src={ImgLocation} />
                                    </div>
                                    <div className="term-cont">
                                        <h4>{strings.Address}</h4>
                                        <p><span className="d-block">The Perfume Smell</span> 83Q9+HQF, Salem Al mubarak, Salmiya, Kuwait</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </section>

            </main>
            <Footer />
        </>

    )
}
export default Contact;