// src/js/reducers/index.js
import { USER_LOGIN } from "../constants/action-types";
const initialState = {
    user: {}
};
const indexReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN:
            return { ...state, user: action.payload };
        default:
            return state;
    }
};
export default indexReducer;
