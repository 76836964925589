import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from "react-router-dom";
import { userLogin } from "../actions/index";
import { connect } from "react-redux";
import apiPath from "../constants/apiPath";
import Http from "../services/Http";

import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ImgPay1 from '../assets/img/pay-1.png';
import ImgPay2 from '../assets/img/pay-2.png';
import ImgPay3 from '../assets/img/pay-3.png';
import walletLogo from '../assets/img/wallet-logo.png';
import ImgCheckout1 from '../assets/img/checkout-1.png';
import ImgCheckout2 from '../assets/img/checkout-2.png';
import ImgCheckout3 from '../assets/img/checkout-3.png';
import ImgCartPrd from '../assets/img/cart_prd.png';
import ImgDelete from '../assets/img/delete.png';
import CommanList from './Component/CommanList';
import { SelectGroup, TextInput, TextInputGroup, ValidationForm } from 'react-bootstrap4-form-validation';
import { Modal, Form, Button, Col, Row } from 'react-bootstrap';
import Currency from './Component/Currency';
import KNET from '../assets/img/knet.png';
import LocalizedStrings from 'react-localization';
import localizations from "../localization";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
let strings = new LocalizedStrings(localizations);


const mapStateToProps = state => {
	return { user: state.user };
};

const mapDispatchToProps = dispatch => {
	return {
		userLogin: user => dispatch(userLogin(user))
	};
}

const Checkout1 = (Props) => {

	const params = useParams();

	const [user, setUser] = useState(Object.keys(Props.user).length > 0 ? Props.user : null)
	const [common, setCommon] = useState({
		immediate: true,
		setFocusOnError: true,
		clearInputOnReset: false,
		image: '',
		is_redirect: false
	});

	const [country, setCountry] = useState([]);
	const [state, setState] = useState([]);
	const [addressType, setAddressType] = useState();
	const [placeAddress, setPlaceAddress] = useState()
	const [getRegister, setRegister] = useState({
		mobile: '',
		country_code: '',
	});
	const onRadioChange = (e) => {
		setAddressType({ [e.target.name]: e.target.value })

	}
	const [comman, setComman] = useState({
		loginModel: false,
		shippingType: params.type,
		latitude: 26.8587263,
		longitude: 75.7720215,
		paymentMode: 'Online',
		cardShow: false,
		processCheckout: false,
		orderId: '',
		immediate: true,
		setFocusOnError: true,
		clearInputOnReset: false,
		discount_code: '',
		applyLoyalty: 0
	});



	const [cartList, setCartList] = useState([]);

	const [currentCurrency, setCurrentCurrency] = useState(
		localStorage.getItem("current_currency")
			? JSON.parse(localStorage.getItem("current_currency"))
			: { currency: 1, country_code: "KWD" }
	)


	const [currentLang, setCurrentLang] = useState(
		localStorage.getItem("current_lang")
			? localStorage.getItem("current_lang")
			: "en"
	);
	strings.setLanguage(currentLang);

	const getCountry = async () => {
		let path = apiPath.getCountry;
		var data = new FormData();
		let options = {
			method: 'POST',
		}
		let result = await Http(path, options);
		if (result.status === 200) {
			if (result.data) {

				let resultJson = result.data.result;
				await setCountry({
					data: result.data.data,
				})



			} else {
				toast.error(result.data.message);
			}
		} else {
			if(result.statusText != null) {
				toast.error(result.statusText);
			}
		}
	}

	const onSubmit = async (e, formData, inputs) => {
		e.preventDefault();
		let path = apiPath.addEditAddress;
		formData.address_type = addressType ? addressType.address_type : "Home"
		formData.address = placeAddress ? placeAddress.data ? placeAddress.data.formatted_address : '' : ''
		formData.mobile_number = getRegister ? getRegister.mobile : ""
		formData.country_code = getRegister ? getRegister.country_code : ''
		formData.is_default = 1
		let options = {
			method: 'POST',
			data: formData
		}
		let result = await Http(path, options);
		if (result.status === 200) {
			if (result.data.status) {

				toast.success(result.data.message);
				window.location.href = "/profile"

			} else {
				toast.error(result.data.message);
			}
		} else {
			if(result.statusText != null) {
				toast.error(result.statusText);
			}
		}
		//   }
	}
	// const handleChange = (value, data, event, formattedValue) => {
	// 	var country_code = data.dialCode;
	// 	setRegister({ country_code: country_code, mobile: value.slice(data.dialCode.length) });
	// }
	const addressErrorSubmit = (e, formData, errorInputs) => {
	}
	const handleChange1 = (e) => {
		if (e.target.name == "country_id") {
			country.data && country.data.map((value) => {

				if (value.id == e.target.value) {
					setState({ data: value.state })
				}
			})
		}
		setUser({ ...user, [e.target.name]: e.target.value })
	}
	const formRef = React.createRef();


	const position = async () => {
		await navigator.geolocation.getCurrentPosition(
			async position => {
				await setComman({ ...comman, latitude: position.coords.latitude, longitude: position.coords.longitude });
				getList(position.coords.latitude, position.coords.longitude, comman.shippingType, 0);
			},
			err => console.log(err)
		);

	}

	const getList = async (latitude, longitude, shippingType, applyLoyalty) => {
		let path = apiPath.getCart;
		var data = new FormData();
		data.append('delivery_type', shippingType);
		data.append('latitude', latitude);
		data.append('longitude', longitude);
		data.append('currency', currentCurrency.country_code);
		if (applyLoyalty == 1) {
			data.append('apply_loyalty_point', applyLoyalty);
		}

		let options = {
			method: 'POST',
			data: data
		}

		let result = await Http(path, options);
		if (result.status == 200) {
			if (result.data.status) {

				await setCartList({ data: result.data.data });
				toast.success(result.data.message);
			}
			else {
				setCartList([]);
				toast.error(result.data.message);
			}
		}
	}

	const applyLoyalty = (e) => {
		if (e.target.checked === true) {
			setComman({ ...comman, applyLoyalty: 1 })
			getList(comman.latitude, comman.longitude, comman.shippingType, 1)
		}
		else {
			setComman({ ...comman, applyLoyalty: 0 })
			getList(comman.latitude, comman.longitude, comman.shippingType, 0)
		}

	}

	const deleteItem = async (id) => {
		let path = apiPath.cartDestroy;
		var data = new FormData();

		if (id != 0) {
			data.append('cart_id', id);
		}

		let options = {
			method: 'POST',
			data: data
		}

		let result = await Http(path, options);
		if (result.status == 200) {
			if (result.data.status) {
				setComman({ ...comman, applyLoyalty: 0 })
				getList(comman.latitude, comman.longitude, comman.shippingType, 0);
				toast.success(result.data.message);
			}
			else {
				toast.error(result.data.message);
			}
		}
	}

	const changePaymentMode = (type) => {
		setComman({ ...comman, paymentMode: type });
	}

	// const processCheckout = async () => {

	// 	let path = apiPath.checkout;
	// 	var data = new FormData();
	// 	data.append('is_wallet_use', comman.paymentMode == "Wallet" ? "Yes" : "No");
	// 	data.append('payment_type', comman.paymentMode == "Wallet" ? "Online" : comman.paymentMode);
	// 	data.append('payment_status', 1);
	// 	let options = {
	// 		method: 'POST',
	// 		data: data
	// 	}

	// 	let result = await Http(path, options);
	// 	if (result.status == 200) {
	// 		if (result.data.status) {
	// 			setComman({ processCheckout: true, orderId: result.data.data.random_order_id })
	// 			toast.success(result.data.message);
	// 		}
	// 		else {
	// 			setCartList([]);
	// 			toast.error(result.data.message);
	// 		}
	// 	}
	// }

	const processCheckout = async () => {

		if (comman.paymentMode == "Online" || comman.paymentMode == "Card") {
			let path = apiPath.paymentByLink;
			var data = new FormData();
			data.append('language', localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') : "en");
			data.append('currency', JSON.parse(localStorage.getItem('current_currency')) ? JSON.parse(localStorage.getItem('current_currency')).country_code : "KWD");
			data.append('payment_type', comman.paymentMode);
			let options = {
				method: 'POST',
				data: data
			}
			let result = await Http(path, options);
			if (result.status == 200) {
				if (result.data.status) {
					window.location.href = result.data.data.paymentlink
					// window.location.href()
					// setComman({ processCheckout: true, orderId: result.data.data.random_order_id })
					// toast.success(result.data.message);
				}
				else {
					setCartList([]);
					toast.error(result.data.message);
				}
			}
		}
		else {
			let path = apiPath.checkout;
			var data = new FormData();
			data.append('is_wallet_use', comman.paymentMode == "Wallet" ? "Yes" : "No");
			data.append('payment_type', comman.paymentMode == "Wallet" ? "Wallet" : comman.paymentMode);
			data.append('payment_status', 1);
			let options = {
				method: 'POST',
				data: data
			}

			let result = await Http(path, options);
			if (result.status == 200) {
				if (result.data.status) {

					setComman({ processCheckout: true, orderId: result.data.data.random_order_id })
					toast.success(result.data.message);
				}
				else {
					setCartList([]);
					toast.error(result.data.message);
				}
			}

		}
	}
	const onSubmitApply = async (e, formData, inputs) => {
		e.preventDefault();

		let path = apiPath.applyDiscount;
		var data = new FormData();
		data.append('discount_code', comman.discount_code);
		let options = {
			method: 'POST',
			data: data
		}

		let result = await Http(path, options);
		if (result.status == 200) {
			if (result.data.status) {

				toast.success(result.data.message);

				getList(comman.latitude, comman.longitude, comman.shippingType, comman.applyLoyalty)


			}
			else {

				toast.error(result.data.message);
			}
		}
	}

	const removeCoupon = async () => {
		let path = apiPath.removeDiscount;
		var data = new FormData();
		data.append('discount_code', comman.discount_code ? comman.discount_code : cartList ? cartList.data ? cartList.data.discount_code : comman.discount_code : null);
		let options = {
			method: 'POST',
			data: data
		}

		let result = await Http(path, options);
		if (result.status == 200) {
			if (result.data.status) {

				toast.success(result.data.message);
				setComman({ ...comman, discount_code: '' })
				getList(comman.latitude, comman.longitude, comman.shippingType, comman.applyLoyalty)


			}
			else {

				toast.error(result.data.message);
			}
		}
	}

	const couponErrorSubmit = () => {

	}

	const handleChange = (e) => {
		setComman({ ...comman, discount_code: e.target.value })
		//setUser({ ...user, [e.target.name]: e.target.value })
	}

	useEffect(() => {
		position()
		getList(comman.latitude, comman.longitude, comman.shippingType, 0)
		getCountry()

	}, [Props]);

	return (
		<>

			{user ? user.details.temp_user == 1 ? <Navigate to="/" replace={true} /> : '' : <Navigate to="/" replace={true} />}



			<Header />
			{comman.processCheckout ? <Navigate to={'/thankyou/ODR' + comman.orderId} data={comman} replace={true} /> : ''}
			<main>
				<div className='addressOuter'>
					<div className='container'>
						<div className='row'>
							<div className='col-xl-8 col-lg-7'>
								<div className='sectionHeading'>Billing details</div>
								<div className="added-address mt-3">

									<ValidationForm onSubmit={onSubmit}
										onErrorSubmit={addressErrorSubmit}
										ref={formRef}
										immediate={common.immediate}
										setFocusOnError={common.setFocusOnError}
										defaultErrorMessage={{ required: "Please enter something." }}
									>
										{/* <h2 className="heading-type2">{strings.shipping_address}</h2> */}
										<div className="address_form">
											<Row>

												<Col xl="6" lg="12" md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>Your Name<span className='text-danger'>*</span></Form.Label>
														<TextInput className="form-control" name="block" id="block" required

															placeholder='Enter Your Name'
															// errorMessage={strings.plz_enter_block}
															// onChange={handleChange1}
														/>
													</Form.Group>

												</Col>
												<Col xl="6" lg="12" md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>Your Email<span className='text-danger'>*</span></Form.Label>
														<TextInput name="street" id="street" required

															placeholder='Enter Your Email'
															// errorMessage={strings.plz_enter_street}
															// onChange={handleChange1}
														/>
													</Form.Group>

												</Col>
												<Col xl="6" lg="12" md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>Mobile number<span className='text-danger'>*</span></Form.Label>
														<PhoneInput inputProps={{
															name: "mobile",
															required: true,
															autoFocus: false,
														}}
															isValid={(value, country) => {
																if (value.match(/1234/)) {
																	return 'Invalid value: ' + value + ', ' + country.name;
																} else if (value.match(/1234/)) {
																	return 'Invalid value: ' + value + ', ' + country.name;
																} else {
																	return true;
																}
															}}
															country={"kw"} onChange={handleChange} />
													</Form.Group>
												</Col>
												<Col xl="6" lg="12" md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>Country<span className='text-danger'>*</span></Form.Label>

														<SelectGroup className="form-control" name="country_id" id="country_id"
															onChange={handleChange1}
															required errorMessage={strings.plz_select_country}
														>
															<option value="">{strings.plz_select_country}</option>
															{country.data && country.data.map((option, index) => {
																return <option key={option.id} value={option.id}>
																	{option.name}
																</option>
															})}
														</SelectGroup>
													</Form.Group>
												</Col>
												{state.data ? state.data.length > 0 ?
													<Col xl="6" lg="12" md="6">
														<Form.Group className="form-group" controlId="form-group">
															<Form.Label>{strings.state}<span className='text-danger'>*</span></Form.Label>

															<SelectGroup className="form-control" name="state_id" id="state_id"
																onChange={handleChange1}
																errorMessage={strings.plz_select_state}
															>
																<option value="">{strings.plz_select_state}</option>
																{state.data && state.data.map((option, index) => {
																	return <option key={option.id} value={option.id} >
																		{option.name}
																	</option>
																})}
															</SelectGroup>
														</Form.Group>
													</Col>
													: <Col xl="6" lg="12" md="6">
														<Form.Group className="form-group" controlId="form-group">
															<Form.Label>{strings.state}<span className='text-danger'>*</span></Form.Label>

															<TextInput name="state_id" id="state_id" required

																placeholder={strings.plz_enter_state}
																errorMessage={strings.plz_enter_state}
																onChange={handleChange1}
															/>


														</Form.Group>
													</Col> : <Col xl="6" lg="12" md="6">
														<Form.Group className="form-group" controlId="form-group">
															<Form.Label>{strings.state}<span className='text-danger'>*</span></Form.Label>
															<TextInput name="state_id" id="state_id" required

																placeholder={strings.plz_enter_state}
																errorMessage={strings.plz_enter_state}
																onChange={handleChange1}
															/>

														</Form.Group>
												</Col>}
												<Col xl="6" lg="12" md="6">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>City<span className='text-danger'>*</span></Form.Label>
														<TextInput name="city" id="city" required

															placeholder='City'
															// errorMessage={strings.plz_enter_street}
															// onChange={handleChange1}
														/>
													</Form.Group>

												</Col>
												<Col md="12">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>Street/Avenue/Block<span className='text-danger'>*</span></Form.Label>
														<TextInput name="city_id" id="city_id" required

															placeholder='Street/Avenue/Block'
															// errorMessage={strings.plz_enter_city}
															onChange={handleChange1}
														/>
													</Form.Group>

												</Col>

												<Col md="12">
													<Form.Group className="form-group" controlId="form-group">
														<Form.Label>{strings.note}(Optional)</Form.Label>
														{/* <TextInput name="notes" id="notes" as="textarea"

															placeholder={strings.pls_enter_note}
															errorMessage={strings.pls_enter_note}
															onChange={handleChange1}
														/> */}
														<Form.Control as="textarea" placeholder="Leave a comment here" rows='5' />
													</Form.Group>

												</Col>
												{/* <Col md="12">
													<div className="form-group formsubmit">
														<button type="submit" className="btn btn_primary ms-auto">Submit</button>
													</div>
												</Col> */}
											</Row>
										</div>
									</ValidationForm>
								</div>
							</div>
							<div className='col-xl-4 col-lg-5'>
								<div className='sectionHeading'>Your order</div>
								
								<div className="cart_row">
								<div className="cart_right m-0">
								<div className='selectedProducts d-flex flex-column gap-3 border-bottom border-white pb-3'>
									<div className='items d-flex align-items-center justify-content-between'>
										<div className='detail d-flex align-items-center gap-md-3 gap-2'>
											<div className='imgOuter rounded-2 overflow-hidden'>
												<img className='w-100 h-100' src="https://fragrancekw.com/wp-content/uploads/2023/03/Club-de-Nuit-Intense-Man-edt-by-armaf-105ml-bottle-300x300.jpg" />
											</div>
											<div className='title'>	Club de Nuit Intense Man × 1</div>
										</div>
										<div className='price'>50 KWD</div>
									</div>
									<div className='items d-flex align-items-center justify-content-between'>
										<div className='detail d-flex align-items-center gap-md-3 gap-2'>
											<div className='imgOuter rounded-2 overflow-hidden'>
												<img className='w-100 h-100' src="https://fragrancekw.com/wp-content/uploads/2023/03/Club-de-Nuit-Intense-Man-edt-by-armaf-105ml-bottle-300x300.jpg" />
											</div>
											<div className='title'>	Club de Nuit Intense Man × 1</div>
										</div>
										<div className='price'>50 KWD</div>
									</div>
									<div className='items d-flex align-items-center justify-content-between'>
										<div className='detail d-flex align-items-center gap-md-3 gap-2'>
											<div className='imgOuter rounded-2 overflow-hidden'>
												<img className='w-100 h-100' src="https://fragrancekw.com/wp-content/uploads/2023/03/Club-de-Nuit-Intense-Man-edt-by-armaf-105ml-bottle-300x300.jpg" />
											</div>
											<div className='title'>	Club de Nuit Intense Man × 1</div>
										</div>
										<div className='price'>50 KWD</div>
									</div>
								</div>
									<div className="cart_title checkout_title">
										<h3>{strings.payment_amount} :  <span className="color-c"><Currency data={cartList.data ? cartList.data.totalAmount : 0} /></span></h3>
									</div>


									<div className="payment_select">
										<h4>{strings.mode_of_payment}</h4>
										<div className="custom_slt">

											<label className="custom_radio_b">
												<input type="radio" name="paymentmode" checked={comman.paymentMode === 'Online' ? true : false} onClick={() => changePaymentMode('Online')} />
												<span className="checkmark"></span>
												<span className="pay-icon">
													<img src={KNET} alt="" />
												</span>
												<span className="pay-option">{strings.knet}</span>
											</label>
											<label className="custom_radio_b">
												<input type="radio" name="paymentmode" checked={comman.paymentMode === 'Card' ? true : false} onClick={() => changePaymentMode('Card')} />
												<span className="checkmark"></span>
												<span className="pay-icon">
													<img src={ImgPay2} alt="" />
												</span>
												<span className="pay-option">{strings.Visa}</span>
											</label>


											<label className="custom_radio_b">
												<input type="radio" name="paymentmode" checked={comman.paymentMode === 'COD' ? true : false} onClick={() => changePaymentMode('COD')} />
												<span className="checkmark"></span>
												<span className="pay-icon">
													<img src={ImgPay3} alt="" />
												</span>
												<span className="pay-option">{strings.cod}</span>
											</label>




											{/* {cartList ? (parseFloat(cartList.data ? cartList.data.wallet > 0? cartList.data.wallet:0 :0) >= parseFloat(cartList.data.totalAmount)) ? */}
												<label className="custom_radio_b">

													<>

														< input type="radio" name="paymentmode" checked={comman.paymentMode === 'Wallet' ? true : false} onClick={() => changePaymentMode('Wallet')} />
														<span className="checkmark"></span>

														<span className="pay-icon">
															<img src={walletLogo} alt="" />
														</span>
														<span className="pay-option">{strings.Wallet} (<Currency data={cartList ? cartList.data ? cartList.data.wallet : 0 : 0} />)</span>
													</>
												</label>
												{/* : <label> <Link to={'/wallet/'} className="btn btn_primary">
													{strings.add_wallet}({cartList ? cartList.data ? cartList.data.wallet : 0 : 0})
												</Link>
												</label>
												: null} */}
										</div>
									</div>


									{/*comman.cardShow ? <form className="address_form">
										<div className="row">
											<div className="col-md-12">
												<div className="form-group">
													<input type="text" name="" placeholder="Account Holder Name" className="form-control" />
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<input type="text" name="" placeholder="Card Number" className="form-control" />
												</div>
											</div>
											<div className="col-md-9">
												<div className="form-group">
													<input type="text" name="" placeholder="Exp. Date" className="form-control" />
												</div>
											</div>
											<div className="col-md-3">
												<div className="form-group">
													<input type="text" name="" placeholder="Exp. Date" className="form-control" />
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group form-submit">
													<a href="#" className="btn btn_primary">Pay Now</a>
												</div>
											</div>
										</div>
											</form> : ""*/}
									<ValidationForm onSubmit={onSubmitApply}
										onErrorSubmit={couponErrorSubmit}
										ref={formRef}
										immediate={comman.immediate}
										setFocusOnError={comman.setFocusOnError}
										defaultErrorMessage={{ required: "Please enter something." }}>

										<div className="form-group">
											<div className="form-wrap">
												<TextInput name="discount_code" value={comman.discount_code} id="discount_code" required placeholder={strings.discount_code} errorMessage="Please enter the Discount code" onChange={handleChange}
												/>
												<div className="subs-btn">
													{cartList.data ? cartList.data.discount_code != null ? <button type="button" className="btn btn_primary" onClick={removeCoupon}>{strings.remove}</button> :
														<button type="submit" className="btn btn_primary">{strings.apply}</button> : ''}
												</div>
											</div>
										</div>
									</ValidationForm>
									<div className="cart_right_title">
										<h3>{strings.price_detail}</h3>
									</div>
									<div className="cart_price">
										<ul>


											{cartList.data ? cartList.data.user_loyalty_points != 0 ?
												<li>
													<span className="price_left"><b>{strings.new_available_loyalty_point}</b></span>

													{/* <span className="price_left"><b>{strings.available_loyalty_point} - ({cartList.data ? cartList.data.user_loyalty_points.toFixed(2) : 0})</b><br /> <small>30% {strings.point_applicable}</small> </span> */}
													<span className="price_right"><input type="checkbox" name="user_loyalty_points" value={cartList.data ? cartList.data.user_loyalty_points : 0} onClick={applyLoyalty} /></span>
												</li> : '' : ''}



											<li>
												<span className="price_left">{strings.price}</span>
												<span className="price_right">
													<Currency data={cartList.data ? cartList.data.amount : 0} />
												</span>
											</li>

											{cartList.data && cartList.data.gift_total_price ?
												<li>
													<span className="price_left">{strings.AddOns}</span>
													<span className="price_right"><Currency data={cartList.data ? cartList.data.gift_total_price : 0} /></span>
												</li> : ''}


											{cartList.data ? cartList.data.apply_loyalty_points != 0 ?
												<li>
													<span className="price_left">{strings.loyalty_point}</span>
													<span className="price_right">-<Currency data={cartList.data ? cartList.data.apply_loyalty_points : 0} /></span>
												</li> : '' : ''}



											{cartList.data && cartList.data.discount_code ?
												<li>
													<span className="price_left">{strings.discount}</span>
													<span className="price_right">- <Currency data={cartList.data.discount_amount} />({cartList.data.discount_code})</span>
												</li> : ''}


											{cartList.data && cartList.data.shipping_charges ?
												<li>
													<span className="price_left">{strings.shipping_fee}</span>
													<span className="price_right"><Currency data={cartList.data.shipping_charges} /></span>
												</li> : ''}

											{cartList.data && cartList.data.tax_amount > 0 ?
												<li>
													<span className="price_left">{strings.tax}</span>
													<span className="price_right"><Currency data={cartList.data.tax_amount} /></span>
												</li> : ''}
										</ul>
									</div>
									<div className="g-total">
										<p>{strings.grand_total}: <Currency data={cartList.data ? cartList.data.totalAmount : 0} /></p>
										<Button type="button" className="btn btn_primary" onClick={processCheckout}>{strings.checkout}</Button>
									</div>
								</div>
									{/* <div className="cart_right pt-3">
										<div className="cart_product_list d-flex flex-column gap-3">
											<div className="cart-product align-items-center border-0 p-0">
												<div className="cart-prd-img border-0">
													<img className='w-100' src="https://fragrancekw.com/wp-content/uploads/2023/03/Club-de-Nuit-Intense-Man-edt-by-armaf-105ml-bottle-300x300.jpg" alt="Cart Product" />
												</div>
												<div className="card-prd-cont">
													<div className="arrival-title">
														<h3>Carlton London × 2</h3>
													</div>
												</div>
												<div className="cart_prd_right">
													<div className="item_total_price">
														<h3>QAR 100.00</h3>
													</div>
												</div>
											</div>
											<div className="cart-product align-items-center border-0 p-0">
												<div className="cart-prd-img border-0">
													<img className='w-100' src="https://fragrancekw.com/wp-content/uploads/2023/03/Club-de-Nuit-Intense-Man-edt-by-armaf-105ml-bottle-300x300.jpg" alt="Cart Product" />
												</div>
												<div className="card-prd-cont">
													<div className="arrival-title">
														<h3>Carlton London × 2</h3>
													</div>
												</div>
												<div className="cart_prd_right">
													<div className="item_total_price">
														<h3>QAR 100.00</h3>
													</div>
												</div>
											</div>
										</div>
										<div className="cart_right_title">
											<h3>Price Detail</h3>
										</div>
										<div className="cart_price">
											<ul>
											<li>
												<span className="price_left">Price</span>
												<span className="price_right">KWD 400</span>
											</li>
											<li>
												<span className="price_left">Shipping Fee</span>
												<span className="price_right">KWD 10</span>
											</li>
											<li className="total_price">
												<span className="price_left">You Pay</span>
												<span className="price_right">KWD 410</span>
											</li>
											</ul>
										</div>
										<div className="g-total">
											<p>Grand Total: KWD 410</p>
											<a href="thankyou.html" className="btn btn_primary">Proceed</a>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</main >
			<Footer />
		</>
	)
}
export default connect(mapStateToProps, mapDispatchToProps)(Checkout1);